import { defineStore } from 'pinia'
import { useCommonMetricsStore } from './common-metrics'
import type { ChatUser, ProductInfoForMsg, SellerActivity, SellersActivity } from '~/types/messenger'

interface State {
  showChat: boolean
  lastInitedChatPayload?: Record<string, any>
  unreadCount: number
  isConnected: boolean
  sellersActivity: SellersActivity
  sellerActivityFetching: boolean
}

interface InitChatOptions {
  actionName: string
  seller?: ChatUser
  productInfo?: ProductInfoForMsg
  blockLocation?: string
}

export const useMessengerStore = defineStore('messenger', {
  state: (): State => ({
    showChat: false,
    lastInitedChatPayload: undefined,
    unreadCount: 0,
    isConnected: false,
    sellersActivity: {},
    sellerActivityFetching: false
  }),
  actions: {
    initChat(payload: InitChatOptions) {
      const commonMetricsStore = useCommonMetricsStore()

      this.lastInitedChatPayload = payload

      commonMetricsStore.addMetricEvent({
        element: 'ms[chat]',
        event_type: 'click',
        event_content: payload.blockLocation || ''
      })
      this.changeWidgetVisibility(true)
    },

    resetInitedChatPayload() {
      this.lastInitedChatPayload = undefined
    },

    changeWidgetVisibility(value: boolean) {
      this.showChat = Boolean(value)
    },

    changeUnreadCount(count: number) {
      this.unreadCount = Number(count)
    },

    changeMessengerConnectState(value: boolean) {
      this.isConnected = Boolean(value)
    },

    addSellerActivity({ uuid, payload }: { uuid: string; payload: SellerActivity }) {
      this.sellersActivity = {
        ...this.sellersActivity,
        [uuid]: payload
      }
    },

    getSellersActivity(uids: Array<string>) {
      this.sellerActivityFetching = true

      try {
        const promises = uids.map(this.checkIsOnline)

        return Promise.all(promises)
      } finally {
        this.sellerActivityFetching = false
      }
    },

    async checkIsOnline(uuid: string) {
      const { $messengerApi } = useNuxtApp()

      const response = await $messengerApi<SellerActivity>(`/api/v2/users/${uuid}/is-online/`)
        .catch(() => ({ is_online: false })) // We don't need to handle error here, just mark seller like offline

      this.addSellerActivity({ uuid, payload: response })
      return response
    }
  }
})
