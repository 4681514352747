import { defineStore } from 'pinia'
import { useAuthUserStore } from './auth-user'
import { useCommonMetricsStore } from './common-metrics'
import { useCheckoutMetaStore } from './checkout-meta'
import { useCheckoutStore } from './checkout'
import { CITY_KYIV_UUID, DELIVERY_DEPARTMENT, DELIVERY_SYNTHETIC_DEPARTMENT, MIST_DELIVERY_METHOD } from '~/constants/common'
import { modalShipmentPaymentChangedNotificationId } from '@/constants/modalNames'
import type { SearchCity } from '~/types/common'
import type { Department } from '~/types/delivery'
import type { PaymentMethod } from '~/types/checkout'

interface State {
  city: SearchCity | null
  delivery: {
    config: string
    method: string
    // departmentString: string
    department: Department | null
    departmentMIST: string
    address: string
    apartment: string
    house: string
  },
  payment: {
    method: string
    maxNumberOfPayments: number
  },
  receiver: {
    firstName: string
    lastName: string
    mobilePhone: string
    email: string
  },
  comment: string
  dontCallMe: boolean
}

let saveDeliveryDataTimer: NodeJS.Timeout | string | number | undefined | null = null
let saveReceiverDataTimer: NodeJS.Timeout | string | number | undefined | null = null
let savePaymentDataTimer: NodeJS.Timeout | string | number | undefined | null = null

export const useCheckoutShipmentStore = defineStore('checkoutShipment', {
  state: (): State => ({
    city: null,
    delivery: {
      config: '',
      method: '',
      department: null,
      departmentMIST: '',
      // departmentString: '',
      address: '',
      apartment: '',
      house: ''
    },
    payment: {
      method: '',
      maxNumberOfPayments: 3
    },
    receiver: {
      firstName: '',
      lastName: '',
      mobilePhone: '',
      email: ''
    },
    comment: '',
    dontCallMe: false
  }),
  getters: {
    selectedDeliveryConfig() {
      const checkoutStore = useCheckoutStore()
      const shipmentDeliveryMethod: string = this.delivery.method

      if (shipmentDeliveryMethod.length === 0) {
        return null
      }

      return checkoutStore.supportedDeliveryConfigs.find((config) => {
        return config.delivery_method.uuid === shipmentDeliveryMethod
      }) || null
    },
    selectedPayment(): PaymentMethod | undefined {
      return this.selectedDeliveryConfig
        ? this.selectedDeliveryConfig?.payment_methods.find(
          (method) => method.uuid === this.payment.method
        )
        : undefined
    },
    receiverData(state) {
      return [
        `${state.receiver.firstName} ${state.receiver.lastName}`,
        state.receiver.mobilePhone,
        state.receiver.email
      ]
        .filter((name) => name?.trim().length > 0)
        .join(' <br> ')
    }
  },
  actions: {
    async changeShipmentCity(payload: SearchCity) {
      const checkoutStore = useCheckoutStore()
      this.city = payload

      localStorage.setItem('saved-shipment-city-uuid', payload.uuid)

      await checkoutStore.updateSupportedDeliveryConfigs()
    },

    async changeShipmentMethod({ deliveryMethod, deliveryConfig }: { deliveryMethod: string, deliveryConfig: string }) {
      const checkoutStore = useCheckoutStore()
      const { $vfm, $api } = useNuxtApp()

      this.delivery.config = deliveryConfig
      this.delivery.method = deliveryMethod

      this.saveDeliveryMethod(deliveryMethod)

      const savedDeliveryDataJSON = localStorage.getItem(`saved-delivery-data-${deliveryMethod}`)

      const selectedDeliveryConfig = checkoutStore.supportedDeliveryConfigs.find((config) => {
        return config.uuid === deliveryConfig
      })

      // Check previews payment method is available in new shipment method and change it if not available
      // START
      const hasSupportedPaymentMethod = selectedDeliveryConfig?.payment_methods.some(
        (paymentMethod) => paymentMethod.uuid === this.payment.method
      )

      // if current payment method not supported in new shipment method we change payment method.
      if (!hasSupportedPaymentMethod) {
        // Show notification if payment method changed
        $vfm.open(modalShipmentPaymentChangedNotificationId)

        this.changeShipmentPaymentMethod(selectedDeliveryConfig?.payment_methods[0].uuid || '')
        this.changeShipmentPaymentMaxNumberOfPayments(3)
      }
      // END

      // Find and set saved delivery data
      // START
      if (savedDeliveryDataJSON) {
        try {
          const savedDeliveryData = JSON.parse(savedDeliveryDataJSON)

          if (savedDeliveryData?.department?.uuid) {
            try {
              // Fetch delivery department (we need refetch department, because the saved data may not be correct)
              const fetchedDepartment = await $api<Department>(`api/deliveries/post-offices/${savedDeliveryData.department.uuid}/`)

              this.changeShipmentDepartment(fetchedDepartment)
            } catch {
              this.changeShipmentDepartment(null)
            }
          }

          this.changeShipmentDepartmentMIST(savedDeliveryData?.departmentMIST || '')
          // dispatch('changeShipmentDepartmentString', savedDeliveryData?.departmentString || '')
          this.changeShipmentAddress(savedDeliveryData?.address || '')
          this.changeShipmentHouse(savedDeliveryData?.house || '')
          this.changeShipmentApartment(savedDeliveryData?.house || '')
        } catch {
          this.changeShipmentDepartment(null)
          this.changeShipmentDepartmentMIST('')
          // dispatch('changeShipmentDepartmentString', '')
          this.changeShipmentAddress('')
          this.changeShipmentHouse('')
          this.changeShipmentApartment('')
        }
      }
      // END
    },
    changeShipmentDepartment(payload: Department | null) {
      this.delivery.department = payload
      this.saveDeliveryData()
    },
    changeShipmentDepartmentMIST(payload: string) {
      this.delivery.departmentMIST = payload
      this.saveDeliveryData()
    },
    // changeShipmentDepartmentString({ commit, dispatch }, payload) {
    //   commit('CHANGE_SHIPMENT_DEPARTMENT_STRING', payload)
    //   dispatch('saveDeliveryData')
    // },
    changeShipmentAddress(payload: string) {
      this.delivery.address = payload
      this.saveDeliveryData()
    },
    changeShipmentHouse(payload: string) {
      this.delivery.house = payload
      this.saveDeliveryData()
    },
    changeShipmentApartment(payload: string) {
      this.delivery.apartment = payload
      this.saveDeliveryData()
    },

    changeShipmentPaymentMethod(payload: string) {
      this.payment.method = payload

      this.changeShipmentPaymentMaxNumberOfPayments(3)

      this.savePaymentData()
    },
    changeShipmentPaymentMaxNumberOfPayments(payload: number) {
      this.payment.maxNumberOfPayments = payload
    },

    changeReceiverFirstName(payload: string) {
      this.receiver.firstName = payload
      this.saveReceiverData()
    },
    changeReceiverLastName(payload: string) {
      this.receiver.lastName = payload
      this.saveReceiverData()
    },
    changeReceiverMobilePhone(payload: string) {
      this.receiver.mobilePhone = payload
      this.saveReceiverData()
    },
    changeReceiverEmail(payload: string) {
      this.receiver.email = payload
      this.saveReceiverData()
    },
    changeComment(payload: string) {
      this.comment = payload
    },
    changeDontCallMe(payload: boolean) {
      this.dontCallMe = payload
    },

    clearCheckoutShipment() {
      this.$reset()
    },

    async setDefaultShipmentCity() {
      const { $api } = useNuxtApp()
      // Set default city uuid for fetching
      const savedShipmentCityUuid = localStorage.getItem('saved-shipment-city-uuid')
      const defaultDeliveryCityUuid = savedShipmentCityUuid || CITY_KYIV_UUID

      return new Promise((resolve, reject) => {
        // Fetch default delivery city (we need refetch city by user language, because the saved data may not be in the correct localization)
        $api<SearchCity>(`/api/common/cities/${defaultDeliveryCityUuid}/`)
          .then((response) => {
            this.city = response

            resolve(response)
          })
          .catch(async () => {
            // get Kyiv city when saved city uuid may be incorect
            const response = await $api<SearchCity>(`/api/common/cities/${CITY_KYIV_UUID}/`)

            this.city = response

            resolve(response)
          })
      })
    },

    async setDefaultDeliveryMethod() {
      const { $api } = useNuxtApp()
      const checkoutStore = useCheckoutStore()
      const lastestDeliveryMethod = localStorage.getItem('saved-delivery-method')
      const lastestDeliveryDataJSON = localStorage.getItem(`saved-delivery-data-${lastestDeliveryMethod}`)

      let savedDeliveryData = null
      let configBySavedDeliveryData = null
      const supportedDeliveryConfigs = checkoutStore.supportedDeliveryConfigs

      try {
        // If saved delivery data by latest delivery method available and valid
        savedDeliveryData = lastestDeliveryDataJSON ? JSON.parse(lastestDeliveryDataJSON) : null
        configBySavedDeliveryData = supportedDeliveryConfigs.find((config) => config.delivery_method.uuid === lastestDeliveryMethod)
      } catch {
        // Else we look for the saved data of any available delivery method.
        for (const config of supportedDeliveryConfigs) {
          const findedSavedDeliveryData = localStorage.getItem(`saved-delivery-data-${config.delivery_method.uuid}`)

          if (findedSavedDeliveryData) {
            savedDeliveryData = findedSavedDeliveryData
            configBySavedDeliveryData = config
            break
          }
        }
      }

      // If saved delivery data available
      if (savedDeliveryData) {
        if (savedDeliveryData?.department?.uuid) {
          try {
            // Fetch delivery department (we need refetch department, because the saved data may not be correct)
            const fetchedDepartment = await $api<Department>(`api/deliveries/post-offices/${savedDeliveryData.department.uuid}/`)

            this.changeShipmentDepartment(fetchedDepartment)
          } catch {
            this.changeShipmentDepartment(null)
          }
        }

        // dispatch('changeShipmentDepartmentString', savedDeliveryData?.departmentString || '')
        this.changeShipmentDepartmentMIST(savedDeliveryData?.departmentMIST || '')
        this.changeShipmentAddress(savedDeliveryData?.address || '')
        this.changeShipmentHouse(savedDeliveryData?.house || '')
        this.changeShipmentApartment(savedDeliveryData?.house || '')
      }

      const defaultShipmentDeliveryConfig = configBySavedDeliveryData || checkoutStore.supportedDeliveryConfigs[0]

      if (defaultShipmentDeliveryConfig) {
        const defaultShipmentDeliveryMethodUuid = defaultShipmentDeliveryConfig.delivery_method.uuid

        this.delivery.config = defaultShipmentDeliveryConfig.uuid
        this.delivery.method = defaultShipmentDeliveryMethodUuid

        this.saveDeliveryMethod(defaultShipmentDeliveryMethodUuid)

        // If default delivery method type === department we will get departments
        if ([DELIVERY_DEPARTMENT, DELIVERY_SYNTHETIC_DEPARTMENT].includes(defaultShipmentDeliveryConfig.delivery_method.type)) {
          const checkoutMetaStore = useCheckoutMetaStore()

          checkoutMetaStore.getDepartments({
            deliveryMethodUuid: defaultShipmentDeliveryMethodUuid,
            cityUuid: this.city?.uuid || '',
            queryParams: {
              offset: 0,
              search: ''
            },
            isUpdate: false
          })
        }
      }
    },

    setDefaultPaymentMethod() {
      const checkoutStore = useCheckoutStore()
      const savedPaymentMethod = localStorage.getItem('saved-payment-method')

      const selectedDeliveryConfig = checkoutStore.supportedDeliveryConfigs.find((config) => {
        return config.uuid === this.delivery.config
      })

      if (selectedDeliveryConfig) {
        const hasSavedPaymentMethod = selectedDeliveryConfig.payment_methods.some(
          (paymentMethod) => paymentMethod.uuid === savedPaymentMethod
        )

        if (hasSavedPaymentMethod && savedPaymentMethod) {
          this.payment.method = savedPaymentMethod
        } else {
          this.payment.method = selectedDeliveryConfig.payment_methods[0].uuid
        }
      }
    },

    setDefaultReceiverData() {
      const authUserStore = useAuthUserStore()
      const savedReceiverDataJSON = localStorage.getItem('saved-receiver-data')
      let savedReceiverData = null

      try {
        savedReceiverData = savedReceiverDataJSON ? JSON.parse(savedReceiverDataJSON) : null
      } catch {}

      this.changeReceiverFirstName(savedReceiverData?.firstName || authUserStore.data?.first_name || '')
      this.changeReceiverLastName(savedReceiverData?.lastName || authUserStore.data?.last_name || '')
      this.changeReceiverMobilePhone(savedReceiverData?.mobilePhone || authUserStore.data?.mobile_phone || '')
      this.changeReceiverEmail(savedReceiverData?.email || authUserStore.data?.email || '')
    },

    saveDeliveryMethod(payload: string) {
      localStorage.setItem('saved-delivery-method', payload)
    },

    saveDeliveryData() {
      if (saveDeliveryDataTimer !== null) {
        clearTimeout(saveDeliveryDataTimer)
        saveDeliveryDataTimer = null
      }

      const deliveryData = {
        department: this.delivery.department,
        departmentMIST: this.delivery.departmentMIST,
        address: this.delivery.address,
        apartment: this.delivery.apartment,
        house: this.delivery.house
      }

      saveDeliveryDataTimer = setTimeout(() => {
        localStorage.setItem(`saved-delivery-data-${this.delivery.method}`, JSON.stringify(deliveryData))

        this.sendSipmentMetrics()
      }, 1000)
    },

    savePaymentData() {
      if (savePaymentDataTimer !== null) {
        clearTimeout(savePaymentDataTimer)
        savePaymentDataTimer = null
      }

      savePaymentDataTimer = setTimeout(() => {
        localStorage.setItem('saved-payment-method', this.payment.method)

        this.sendSipmentMetrics()
      }, 1000)
    },

    saveReceiverData() {
      if (saveReceiverDataTimer !== null) {
        clearTimeout(saveReceiverDataTimer)
        saveReceiverDataTimer = null
      }

      saveReceiverDataTimer = setTimeout(() => {
        localStorage.setItem('saved-receiver-data', JSON.stringify(this.receiver))

        this.sendSipmentMetrics()
      }, 1000)
    },

    sendSipmentMetrics() {
      const commonMetricsStore = useCommonMetricsStore()

      const deliveryData = {
        delivery_city: this.city?.name || '',
        delivery_method: this.delivery.method,
        delivery_department: this.delivery.method === MIST_DELIVERY_METHOD
          ? this.delivery.departmentMIST
          : (this.delivery?.department?.number || ''),
        // delivery_department: state.delivery.departmentString,
        delivery_address: this.delivery.address,
        delivery_house: this.delivery.house,
        delivery_apartment: this.delivery.apartment
      }
      const paymentData = {
        method: this.payment.method,
        maxNumberOfPayments: this.payment.maxNumberOfPayments
      }
      const receiverDataForMetrics = {
        receiver_email: '*'.repeat(this.receiver.email.length),
        receiver_first_name: '*'.repeat(this.receiver.firstName.length),
        receiver_last_name: '*'.repeat(this.receiver.lastName.length),
        receiver_mobile_phone: '*'.repeat(this.receiver.mobilePhone.length)
      }

      // Send metrics with shipment info.
      commonMetricsStore.addMetricEvent({
        element: 'ms[checkout-shipment]',
        event_type: 'input',
        event_content: {
          delivery: deliveryData,
          receiver: receiverDataForMetrics,
          payment: paymentData
        }
      })
    }
  }
})
