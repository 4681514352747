<template>
  <button
    v-if="!hasLink"
    class="button"
    :class="[
      `button--${theme}`,
      `button--${size}`,
      {
        'button--full-width': fullWidth,
        'button--disabled': disabled,
        'button--outline': outline
      }
    ]"
    :style="{
      'min-width': minWidth,
      'max-width': maxWidth
    }"
    :type="type"
    :disabled="disabled"
    @click="handleClick"
  >
    <span class="button__cn">
      <span
        v-show="loading"
        class="button__loader"
        :class="[
          `button__loader--${theme}`,
          { 'button__loader--outline': outline }
        ]"
      >
        <BaseLoader
          v-if="!theme.includes('link')"
          :theme="loaderTheme"
          :size="theme === 'simple' ? 'xs' : 'md'"
        />
      </span>

      <span v-if="$slots.prefix && !loading" class="prefix">
        <slot name="prefix" />
      </span>

      <!-- @slot Use this slot for button label -->
      <span class="content">
        <slot />
      </span>

      <span v-if="$slots.suffix" class="suffix">
        <slot name="suffix" />
      </span>
    </span>
  </button>

  <a
    v-else
    :href="localePath(to)"
    class="button"
    :class="[
      `button--${size}`,
      `button--${theme}`,
      {
        'button--full-width': fullWidth,
        'button--outline': outline
      }
    ]"
    :style="{
      'min-width': minWidth,
      'max-width': maxWidth
    }"
    @click.prevent="handleClickLink(to)"
  >
    <div v-if="$slots.prefix" class="prefix">
      <slot name="prefix" />
    </div>

    <!-- @slot Use this slot for button label -->
    <span class="content">
      <slot />
    </span>

    <div v-if="$slots.suffix" class="suffix">
      <slot name="suffix" />
    </div>
  </a>
</template>

<script setup lang="ts">
import BaseLoader from '@/components/Base/BaseLoader/BaseLoader.vue'

type Props = {
  theme?: 'primary' | 'secondary' | 'accent' | 'simple' | 'link' | 'error'
  size?: 'normal' | 'small' | 'extra-small' | 'small-mobile'
  type?: 'submit' | 'reset' | 'button'
  outline?: boolean
  to?: string
  fullWidth?: boolean
  minWidth?: string
  maxWidth?: string
  loading?: boolean
  disabled?: boolean
  target?: '_blank' | '_self' | '_parent' | '_top'
}

type Emits = {
  (e: 'click', ev: MouseEvent): void
  (e: 'url-click', url: string): void
}

const props = withDefaults(defineProps<Props>(), {
  theme: 'primary',
  size: 'normal',
  type: 'button',
  to: '',
  target: '_self',
  minWidth: '',
  maxWidth: ''
})
const emit = defineEmits<Emits>()
const localePath = useLocalePath()
const router = useRouter()

const hasLink = computed(() => {
  const { to } = props

  return !!to || (typeof to === 'object' && Object.keys(to).length > 0)
})

const loaderTheme = computed(() => {
  const { theme, outline } = props

  switch (theme) {
    case 'primary':
      return !outline ? 'white' : theme

    case 'accent':
      return outline
        ? 'accent'
        : 'white'
  }
})

const handleClick = (e: MouseEvent) => {
  props.loading
    ? e.preventDefault()
    : emit('click', e)
}

const handleClickLink = (url: string) => {
  emit('url-click', url)

  if (props.target === '_blank') {
    window.open(url, '_blank')?.focus()
  } else {
    router.push(localePath(url))
  }
}

</script>

<style lang="scss" scoped>
.button {
  font-family: $font-family;
  $root: &;
  height: 4.8rem;
  border: none;
  outline: none!important;
  background-color: $primary-color;
  font-size: 1.4rem;
  color: $white-color;
  cursor: pointer;
  padding: 0 2rem;
  min-width: 14rem;
  border-radius: 0.8rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  position: relative;

  @media (max-width: 340px) {
    padding: 0 1.4rem;
  }

  &__cn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .prefix {
    padding-right: .8rem;
    position: relative;
    top: 0.1rem;
  }

  .suffix {
    padding-left: 1.6rem;
    position: relative;
    top: 0.1rem;
  }

  &--primary {
    font-size: 16px;
    color: $font-color;
    background-color: $primary-color;
    transition: background-color $transition-time, box-shadow $transition-time, ;
    font-weight: 500;

    &:hover {
      background-color: rgba($primary-color, .8);
    }

    &#{$root}--outline {
      background-color: transparent;
      border: solid 0.2rem $primary-color;
      color: $font-color;

      // [class^="icon-"], [class*=" icon-"] {
      //   color: $primary-color;
      // }

      &:hover {
        box-shadow: inset 0 0 0 0.1rem $primary-color;
        box-sizing: border-box;

        [class^="icon-"], [class*=" icon-"] {
          opacity: .8;
        }
      }
    }
  }

  &--secondary {
    background: #F0F0F0;
    color: $font-color;
    transition: background $transition-time, box-shadow $transition-time;

    &:hover {
      background: #E7E7E7;
    }

    &#{$root}--outline {
      background-color: transparent;
      border: solid 0.2rem  #E5E5E5;
      color: $link-color;

      &:hover {
        box-shadow: inset 0 0 0 0.1rem #E5E5E5;
      }
    }
  }

  &--accent {
    font-size: 1.6rem;
    font-weight: 500;
    background: $accent-color;
    transition: background-color $transition-time, box-shadow $transition-time;

    &:hover {
      background-color:#00B744;
    }

    &#{$root}--outline {
      background: transparent;
      border: solid 0.2rem $accent-color;
      color: $font-color;

      [class^="icon-"], [class*=" icon-"] {
        color: $accent-color;
      }

      &:hover {
        box-shadow: inset 0 0 0 0.1rem $accent-color;

        [class^="icon-"], [class*=" icon-"] {
          opacity: .8;
        }
      }
    }
  }

  &--simple {
    height: 3.2rem;
    color: $link-color;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 0 1.6rem;
    min-width: auto;
    transition: box-shadow $transition-time, color $transition-time;

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      color: #5F83CB;
    }
  }

  &--error {
    color: #fff;
    background-color: #DE1C24;
    transition: background-color $transition-time, border-color $transition-time;

    &:hover {
      background-color: rgba(#DE1C24, .8);
    }

    &#{$root}--outline {
      background-color: transparent;
      border: solid 0.2rem  #DE1C24;
      color: $font-color;

      &:hover {
        border-color: rgba(#DE1C24, .8);
      }
    }
  }

  &--link {
    display: inline-flex;
    font-weight: 400;
    padding: 0;
    height: auto;
    min-width: auto;
    background-color: transparent;
    color: $link-color;
    font-size: 1.4rem;
    transition: color $transition-time;

    &#{$root}--small {
      height: auto;
      padding-left: 0;
      padding-right: 0;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    &#{$root}--small-mobile {
      @media only screen and (max-width: 740px) {
        height: auto;
        padding-left: 0;
        padding-right: 0;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    &#{$root}--disabled {
      background: transparent!important;
      color: #A2A2A2!important;
      pointer-events: none;
    }

    &:hover {
      color: #5F83CB;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--disabled {
    background: #f3f3f3!important;
    border: none!important;
    pointer-events: none;
    /*cursor: not-allowed!important;*/
    color: #A2A2A2!important;
    transition: background $transition-time, border-color $transition-time;

    &#{$root}--simple {
      border: none!important;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)!important;
    }

    .prefix,
    .sufix {
      opacity: 0.4;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.8rem;

    &--primary {
      background: $primary-color;
    }

    &--secondary {
      background: #F0F0F0;
    }

    &--accent {
      background: $accent-color;
    }

    &--outline {
      background-color: #fff;
    }

    &--simple {
      background-color: #fff;
    }
  }

  &--small {
    height: 4rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;

    .prefix {
      padding-right: .6rem;
      top: 0.2rem;
    }

    .suffix {
      padding-left: .6rem;
      top: 0.2rem;
    }
  }

  &--small-mobile {
    @media only screen and (max-width: 1024px) {
      height: 4rem;
      padding-left: 1.3rem;
      padding-right: 1.3rem;

      .prefix {
        padding-right: .6rem;
        top: 0.2rem;
      }

      .suffix {
        padding-left: .6rem;
        top: 0.2rem;
      }
    }
  }

  &--extra-small {
    height: 2.4rem;
    padding-left: .8rem;
    padding-right: .8rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 400;
    border-radius: .4rem;
    border-width: .1rem!important;
  }
}
</style>
