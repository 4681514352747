
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as searchWiCwPORWRkcZm1zH29UWKNMF4Vb5hM_q5Mi2yI40hlcMeta } from "/app/pages/search.vue?macro=true";
import { default as bonusesAnUFw7crxI5V7jHjSRwjsrRuKO77fCn94c49zQduvJUMeta } from "/app/pages/bonuses.vue?macro=true";
import { default as captchaVjt9uvTEV8vsANuyAIjkauyRdnfTddJSe4mTMXMiorwMeta } from "/app/pages/captcha.vue?macro=true";
import { default as indexOT_qiHXC01hnlTMZjyFN4B5k_45SxAg4D3aM8UHjeljxIMeta } from "/app/pages/l/index.vue?macro=true";
import { default as loginHOGV2v2PINdTIHc7UC2kEvGCEOmL3_45VnFH_Nuw9GeeMMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as bestsellerP2o9A_gt8W1tZFArkWvYTBvjVrC8iS7BNIpospeImpQMeta } from "/app/pages/bestseller.vue?macro=true";
import { default as indexw0W4b5NW1cZcO3wk718rZDxBSNRcs9oVpHh5ueTNwv0Meta } from "/app/pages/tags/index.vue?macro=true";
import { default as index_45NcRSnBLqTeN_BfrunUR1Ry1ttV52YZTDlyUKgVGVU8Meta } from "/app/pages/pages/index.vue?macro=true";
import { default as index9bHh30BJd5UQMgnJlacq_45RrLZgSFwKr77_sFnnOUfNQMeta } from "/app/pages/return/index.vue?macro=true";
import { default as index1gGC0yVuQFrHtlL8d_45RQIzqyCHRI0ceCV16hD0J79PkMeta } from "/app/pages/seller/index.vue?macro=true";
import { default as registerP6kYPRY6AO3o2k9aPJs1KTHwpt17Hy4EvBog_g8SDPYMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as indexORcYTFjHLQpqcgd3P5IMqpQI7sHF7EO6URVNUIbeQicMeta } from "/app/pages/product/index.vue?macro=true";
import { default as indexPYMehh1H3I51I_Jt_45B2w91jhAIWvwCrr7OI55V5sjLcMeta } from "/app/pages/profile/index.vue?macro=true";
import { default as index7PHmsvhFvB_g5kZxgiuxoeT0bdw2nPdkEwlj4tb_456q4Meta } from "/app/pages/checkout/index.vue?macro=true";
import { default as ordersh_45k_qd6MmNABra7oJwYHB04o7QXkFjLmK4_45bDJPdyEAMeta } from "/app/pages/profile/orders.vue?macro=true";
import { default as bonusest7r6d2usj7wHHRIarp9RXWFdjfAPcwqB9rRaya3e5s8Meta } from "/app/pages/profile/bonuses.vue?macro=true";
import { default as returnsOD_wZxcwwNl1bssQF9QgGILoCA7kp9W8PNrZ_45jjQZisMeta } from "/app/pages/profile/returns.vue?macro=true";
import { default as completei31uLd5zQcp3onagGLo2zAPq0EiMMR6HFHQSVJB79TAMeta } from "/app/pages/return/complete.vue?macro=true";
import { default as synthetic_45promoltUoMMzd9Skx9ufzGrKaXIHBGgpzYbb5BRfb9h5aCCsMeta } from "/app/pages/synthetic-promo.vue?macro=true";
import { default as success8d_3EOAK5qTHXDi5aUdopcqxR1SKNjO_450FYerflLyrcMeta } from "/app/pages/checkout/success.vue?macro=true";
import { default as settingsd4_45MiyAPiyy7jAlhaX9bdhhDnO9k2pBltk1j4sr2A9YMeta } from "/app/pages/profile/settings.vue?macro=true";
import { default as wishlistmYTPV08ebZalSs5dvDrTtU0UxM4gtfc4jBfS1w3UpfAMeta } from "/app/pages/profile/wishlist.vue?macro=true";
import { default as reset_password6WomSDhLoXrb8_453tNIhg9Z64eR_yDdwTFSLn066OP7IMeta } from "/app/pages/auth/reset_password.vue?macro=true";
import { default as indexnzHzjIwBQ_Hldz_45ayZqV79ZftKMDpm9sPzNpsD214DwMeta } from "/app/pages/tags/[tagSlug]/index.vue?macro=true";
import { default as subscriptionsgv8KeVW9FbiVl4jQoEUyIzBLawhqTImtNsg_45JBBW_456oMeta } from "/app/pages/profile/subscriptions.vue?macro=true";
import { default as indexs9uRdgQVBNP2AuW7F0WUD4Lmkod1nlLAj_jOO5f_Gw4Meta } from "/app/pages/l/[saleCompanyId]/index.vue?macro=true";
import { default as product_45reviews6NM1X8xSytY9bee7_45jwbnymLbllptMqXT_45q5hAlp0UsMeta } from "/app/pages/profile/product-reviews.vue?macro=true";
import { default as aid3SPb6PW5a1_8edLs2DThkedyMHuebRXuZiNjvYXpX1MMeta } from "/app/pages/pages/[categorySlug]/aid.vue?macro=true";
import { default as referral_45program8w61G8SE6oFigLZzzoBSTj4W4EHWLOecTNab_nLcBnoMeta } from "/app/pages/profile/referral-program.vue?macro=true";
import { default as sellI5A_456tAic5ay2VZHbB2buxhnJfxGW7AZsYE4x_eJMwUMeta } from "/app/pages/pages/[categorySlug]/sell.vue?macro=true";
import { default as indexSjM5WPOYKR2u4E6LAovB3gJMMBZWlS07Ro0FLzKwjtgMeta } from "/app/pages/seller/[sellerSlug]/index.vue?macro=true";
import { default as _91userId_93gOPlN8FrmGiKQKRAejYpVOWGKNWXcrptNhL1mLtxfAYMeta } from "/app/pages/l/[saleCompanyId]/[userId].vue?macro=true";
import { default as indexe5TuRf7RXXf_45pPzEXvPj4LOZYgr5aMim4OFvV2pWBPQMeta } from "/app/pages/product/[productName]/index.vue?macro=true";
import { default as indexWvgWmzBdPUHjJO25NrXao_bLM9nxSbCvEtAQe2ycVHkMeta } from "/app/pages/category/[categorySlug]/index.vue?macro=true";
import { default as indexBApwEvfagAs_tBjaIlqc3Te_45JvI7AI2caeACaVcHLOQMeta } from "/app/pages/email/confirm/_uid/_token/index.vue?macro=true";
import { default as _91pageSlug_93lFPcwoxUpuXYAazcYAW_458MZ9gMDAbicOwfm7YJuHCwoMeta } from "/app/pages/pages/[categorySlug]/[pageSlug].vue?macro=true";
import { default as indexD6ZWs37jw2AdcoLLdTVwzAtTrNB7tVbo_2u6CDXnmhwMeta } from "/app/pages/seller/[sellerSlug]/reviews/index.vue?macro=true";
import { default as index5iPil8__45NYuMTcii1gyZhQGgW4ZjUgHPawF3CxIXRpgMeta } from "/app/pages/product/[productName]/[productId]/index.vue?macro=true";
import { default as synthetic_45associatesI_GhazYlnH8bk9FnKd5SJO5gA1IriFuUi2GgizMBROUMeta } from "/app/pages/pages/[categorySlug]/synthetic-associates.vue?macro=true";
import { default as index5AOW2PY0QqneERAmCZsokEpxvlpd7o2V9_DuASzM6EoMeta } from "/app/pages/category/[categorySlug]/[categoryId]/index.vue?macro=true";
import { default as create_45review_45uRJtcu7z9SCsPwTxOSwMpuB4h5sznPy_45PtA71f0lgEMeta } from "/app/pages/product/[productName]/[productId]/create-review.vue?macro=true";
import { default as indexnduqHm17RVAHtm4l915BfhgvTVQOeuRRAyybl0pdIxEMeta } from "/app/pages/product/[productName]/[productId]/[productCode]/index.ts?macro=true";
import { default as indexAlupXG3vsQToMugH8DDkmISpHv4VM_459WXj3KoeXdvQsMeta } from "/app/pages/category/[categorySlug]/[categoryId]/[categoryFilters]/index.ts?macro=true";
import { default as create_45reviewiqqrCi_PWQK6LEwqGCFpERxII7sjrwFqfKHlZfm6EMUMeta } from "/app/pages/product/[productName]/[productId]/[productCode]/create-review.ts?macro=true";
export default [
  {
    name: "index___ua",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "search___ua",
    path: "/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___ru",
    path: "/ru/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "bonuses___ua",
    path: "/bonuses",
    component: () => import("/app/pages/bonuses.vue")
  },
  {
    name: "bonuses___ru",
    path: "/ru/bonuses",
    component: () => import("/app/pages/bonuses.vue")
  },
  {
    name: "captcha___ua",
    path: "/captcha",
    component: () => import("/app/pages/captcha.vue")
  },
  {
    name: "captcha___ru",
    path: "/ru/captcha",
    component: () => import("/app/pages/captcha.vue")
  },
  {
    name: "l___ua",
    path: "/l",
    component: () => import("/app/pages/l/index.vue")
  },
  {
    name: "l___ru",
    path: "/ru/l",
    component: () => import("/app/pages/l/index.vue")
  },
  {
    name: "auth-login___ua",
    path: "/auth/login",
    meta: loginHOGV2v2PINdTIHc7UC2kEvGCEOmL3_45VnFH_Nuw9GeeMMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "auth-login___ru",
    path: "/ru/auth/login",
    meta: loginHOGV2v2PINdTIHc7UC2kEvGCEOmL3_45VnFH_Nuw9GeeMMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "bestseller___ua",
    path: "/bestseller",
    component: () => import("/app/pages/bestseller.vue")
  },
  {
    name: "bestseller___ru",
    path: "/ru/bestseller",
    component: () => import("/app/pages/bestseller.vue")
  },
  {
    name: "tags___ua",
    path: "/tags",
    component: () => import("/app/pages/tags/index.vue")
  },
  {
    name: "tags___ru",
    path: "/ru/tags",
    component: () => import("/app/pages/tags/index.vue")
  },
  {
    name: "pages___ua",
    path: "/pages",
    component: () => import("/app/pages/pages/index.vue")
  },
  {
    name: "pages___ru",
    path: "/ru/pages",
    component: () => import("/app/pages/pages/index.vue")
  },
  {
    name: "return___ua",
    path: "/return",
    component: () => import("/app/pages/return/index.vue")
  },
  {
    name: "return___ru",
    path: "/ru/return",
    component: () => import("/app/pages/return/index.vue")
  },
  {
    name: "seller___ua",
    path: "/seller",
    component: () => import("/app/pages/seller/index.vue")
  },
  {
    name: "seller___ru",
    path: "/ru/seller",
    component: () => import("/app/pages/seller/index.vue")
  },
  {
    name: "auth-register___ua",
    path: "/auth/register",
    meta: registerP6kYPRY6AO3o2k9aPJs1KTHwpt17Hy4EvBog_g8SDPYMeta || {},
    component: () => import("/app/pages/auth/register.vue")
  },
  {
    name: "auth-register___ru",
    path: "/ru/auth/register",
    meta: registerP6kYPRY6AO3o2k9aPJs1KTHwpt17Hy4EvBog_g8SDPYMeta || {},
    component: () => import("/app/pages/auth/register.vue")
  },
  {
    name: "product___ua",
    path: "/product",
    component: () => import("/app/pages/product/index.vue")
  },
  {
    name: "product___ru",
    path: "/ru/product",
    component: () => import("/app/pages/product/index.vue")
  },
  {
    name: "profile___ua",
    path: "/profile",
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile",
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "checkout___ua",
    path: "/checkout",
    meta: index7PHmsvhFvB_g5kZxgiuxoeT0bdw2nPdkEwlj4tb_456q4Meta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___ru",
    path: "/ru/checkout",
    meta: index7PHmsvhFvB_g5kZxgiuxoeT0bdw2nPdkEwlj4tb_456q4Meta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "profile-orders___ua",
    path: "/profile/orders",
    meta: ordersh_45k_qd6MmNABra7oJwYHB04o7QXkFjLmK4_45bDJPdyEAMeta || {},
    component: () => import("/app/pages/profile/orders.vue")
  },
  {
    name: "profile-orders___ru",
    path: "/ru/profile/orders",
    meta: ordersh_45k_qd6MmNABra7oJwYHB04o7QXkFjLmK4_45bDJPdyEAMeta || {},
    component: () => import("/app/pages/profile/orders.vue")
  },
  {
    name: "profile-bonuses___ua",
    path: "/profile/bonuses",
    meta: bonusest7r6d2usj7wHHRIarp9RXWFdjfAPcwqB9rRaya3e5s8Meta || {},
    component: () => import("/app/pages/profile/bonuses.vue")
  },
  {
    name: "profile-bonuses___ru",
    path: "/ru/profile/bonuses",
    meta: bonusest7r6d2usj7wHHRIarp9RXWFdjfAPcwqB9rRaya3e5s8Meta || {},
    component: () => import("/app/pages/profile/bonuses.vue")
  },
  {
    name: "profile-returns___ua",
    path: "/profile/returns",
    meta: returnsOD_wZxcwwNl1bssQF9QgGILoCA7kp9W8PNrZ_45jjQZisMeta || {},
    component: () => import("/app/pages/profile/returns.vue")
  },
  {
    name: "profile-returns___ru",
    path: "/ru/profile/returns",
    meta: returnsOD_wZxcwwNl1bssQF9QgGILoCA7kp9W8PNrZ_45jjQZisMeta || {},
    component: () => import("/app/pages/profile/returns.vue")
  },
  {
    name: "return-complete___ua",
    path: "/return/complete",
    component: () => import("/app/pages/return/complete.vue")
  },
  {
    name: "return-complete___ru",
    path: "/ru/return/complete",
    component: () => import("/app/pages/return/complete.vue")
  },
  {
    name: "synthetic-promo___ua",
    path: "/synthetic-promo",
    meta: synthetic_45promoltUoMMzd9Skx9ufzGrKaXIHBGgpzYbb5BRfb9h5aCCsMeta || {},
    component: () => import("/app/pages/synthetic-promo.vue")
  },
  {
    name: "synthetic-promo___ru",
    path: "/ru/synthetic-promo",
    meta: synthetic_45promoltUoMMzd9Skx9ufzGrKaXIHBGgpzYbb5BRfb9h5aCCsMeta || {},
    component: () => import("/app/pages/synthetic-promo.vue")
  },
  {
    name: "checkout-success___ua",
    path: "/checkout/success",
    component: () => import("/app/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___ru",
    path: "/ru/checkout/success",
    component: () => import("/app/pages/checkout/success.vue")
  },
  {
    name: "profile-settings___ua",
    path: "/profile/settings",
    meta: settingsd4_45MiyAPiyy7jAlhaX9bdhhDnO9k2pBltk1j4sr2A9YMeta || {},
    component: () => import("/app/pages/profile/settings.vue")
  },
  {
    name: "profile-settings___ru",
    path: "/ru/profile/settings",
    meta: settingsd4_45MiyAPiyy7jAlhaX9bdhhDnO9k2pBltk1j4sr2A9YMeta || {},
    component: () => import("/app/pages/profile/settings.vue")
  },
  {
    name: "profile-wishlist___ua",
    path: "/profile/wishlist",
    meta: wishlistmYTPV08ebZalSs5dvDrTtU0UxM4gtfc4jBfS1w3UpfAMeta || {},
    component: () => import("/app/pages/profile/wishlist.vue")
  },
  {
    name: "profile-wishlist___ru",
    path: "/ru/profile/wishlist",
    meta: wishlistmYTPV08ebZalSs5dvDrTtU0UxM4gtfc4jBfS1w3UpfAMeta || {},
    component: () => import("/app/pages/profile/wishlist.vue")
  },
  {
    name: "auth-reset_password___ua",
    path: "/auth/reset_password",
    meta: reset_password6WomSDhLoXrb8_453tNIhg9Z64eR_yDdwTFSLn066OP7IMeta || {},
    component: () => import("/app/pages/auth/reset_password.vue")
  },
  {
    name: "auth-reset_password___ru",
    path: "/ru/auth/reset_password",
    meta: reset_password6WomSDhLoXrb8_453tNIhg9Z64eR_yDdwTFSLn066OP7IMeta || {},
    component: () => import("/app/pages/auth/reset_password.vue")
  },
  {
    name: "tags-tagSlug___ua",
    path: "/tags/:tagSlug()",
    component: () => import("/app/pages/tags/[tagSlug]/index.vue")
  },
  {
    name: "tags-tagSlug___ru",
    path: "/ru/tags/:tagSlug()",
    component: () => import("/app/pages/tags/[tagSlug]/index.vue")
  },
  {
    name: "profile-subscriptions___ua",
    path: "/profile/subscriptions",
    meta: subscriptionsgv8KeVW9FbiVl4jQoEUyIzBLawhqTImtNsg_45JBBW_456oMeta || {},
    component: () => import("/app/pages/profile/subscriptions.vue")
  },
  {
    name: "profile-subscriptions___ru",
    path: "/ru/profile/subscriptions",
    meta: subscriptionsgv8KeVW9FbiVl4jQoEUyIzBLawhqTImtNsg_45JBBW_456oMeta || {},
    component: () => import("/app/pages/profile/subscriptions.vue")
  },
  {
    name: "l-saleCompanyId___ua",
    path: "/l/:saleCompanyId()",
    component: () => import("/app/pages/l/[saleCompanyId]/index.vue")
  },
  {
    name: "l-saleCompanyId___ru",
    path: "/ru/l/:saleCompanyId()",
    component: () => import("/app/pages/l/[saleCompanyId]/index.vue")
  },
  {
    name: "profile-product-reviews___ua",
    path: "/profile/product-reviews",
    meta: product_45reviews6NM1X8xSytY9bee7_45jwbnymLbllptMqXT_45q5hAlp0UsMeta || {},
    component: () => import("/app/pages/profile/product-reviews.vue")
  },
  {
    name: "profile-product-reviews___ru",
    path: "/ru/profile/product-reviews",
    meta: product_45reviews6NM1X8xSytY9bee7_45jwbnymLbllptMqXT_45q5hAlp0UsMeta || {},
    component: () => import("/app/pages/profile/product-reviews.vue")
  },
  {
    name: "pages-categorySlug-aid___ua",
    path: "/pages/:categorySlug()/aid",
    component: () => import("/app/pages/pages/[categorySlug]/aid.vue")
  },
  {
    name: "pages-categorySlug-aid___ru",
    path: "/ru/pages/:categorySlug()/aid",
    component: () => import("/app/pages/pages/[categorySlug]/aid.vue")
  },
  {
    name: "profile-referral-program___ua",
    path: "/profile/referral-program",
    meta: referral_45program8w61G8SE6oFigLZzzoBSTj4W4EHWLOecTNab_nLcBnoMeta || {},
    component: () => import("/app/pages/profile/referral-program.vue")
  },
  {
    name: "profile-referral-program___ru",
    path: "/ru/profile/referral-program",
    meta: referral_45program8w61G8SE6oFigLZzzoBSTj4W4EHWLOecTNab_nLcBnoMeta || {},
    component: () => import("/app/pages/profile/referral-program.vue")
  },
  {
    name: "pages-categorySlug-sell___ua",
    path: "/pages/:categorySlug()/sell",
    meta: sellI5A_456tAic5ay2VZHbB2buxhnJfxGW7AZsYE4x_eJMwUMeta || {},
    component: () => import("/app/pages/pages/[categorySlug]/sell.vue")
  },
  {
    name: "pages-categorySlug-sell___ru",
    path: "/ru/pages/:categorySlug()/sell",
    meta: sellI5A_456tAic5ay2VZHbB2buxhnJfxGW7AZsYE4x_eJMwUMeta || {},
    component: () => import("/app/pages/pages/[categorySlug]/sell.vue")
  },
  {
    name: "seller-sellerSlug___ua",
    path: "/seller/:sellerSlug()",
    component: () => import("/app/pages/seller/[sellerSlug]/index.vue")
  },
  {
    name: "seller-sellerSlug___ru",
    path: "/ru/seller/:sellerSlug()",
    component: () => import("/app/pages/seller/[sellerSlug]/index.vue")
  },
  {
    name: "l-saleCompanyId-userId___ua",
    path: "/l/:saleCompanyId()/:userId()",
    meta: _91userId_93gOPlN8FrmGiKQKRAejYpVOWGKNWXcrptNhL1mLtxfAYMeta || {},
    component: () => import("/app/pages/l/[saleCompanyId]/[userId].vue")
  },
  {
    name: "l-saleCompanyId-userId___ru",
    path: "/ru/l/:saleCompanyId()/:userId()",
    meta: _91userId_93gOPlN8FrmGiKQKRAejYpVOWGKNWXcrptNhL1mLtxfAYMeta || {},
    component: () => import("/app/pages/l/[saleCompanyId]/[userId].vue")
  },
  {
    name: "product-productName___ua",
    path: "/product/:productName()",
    component: () => import("/app/pages/product/[productName]/index.vue")
  },
  {
    name: "product-productName___ru",
    path: "/ru/product/:productName()",
    component: () => import("/app/pages/product/[productName]/index.vue")
  },
  {
    name: "category-categorySlug___ua",
    path: "/category/:categorySlug()",
    component: () => import("/app/pages/category/[categorySlug]/index.vue")
  },
  {
    name: "category-categorySlug___ru",
    path: "/ru/category/:categorySlug()",
    component: () => import("/app/pages/category/[categorySlug]/index.vue")
  },
  {
    name: "email-confirm-_uid-_token___ua",
    path: "/email/confirm/_uid/_token",
    component: () => import("/app/pages/email/confirm/_uid/_token/index.vue")
  },
  {
    name: "email-confirm-_uid-_token___ru",
    path: "/ru/email/confirm/_uid/_token",
    component: () => import("/app/pages/email/confirm/_uid/_token/index.vue")
  },
  {
    name: "pages-categorySlug-pageSlug___ua",
    path: "/pages/:categorySlug()/:pageSlug()",
    component: () => import("/app/pages/pages/[categorySlug]/[pageSlug].vue")
  },
  {
    name: "pages-categorySlug-pageSlug___ru",
    path: "/ru/pages/:categorySlug()/:pageSlug()",
    component: () => import("/app/pages/pages/[categorySlug]/[pageSlug].vue")
  },
  {
    name: "seller-sellerSlug-reviews___ua",
    path: "/seller/:sellerSlug()/reviews",
    component: () => import("/app/pages/seller/[sellerSlug]/reviews/index.vue")
  },
  {
    name: "seller-sellerSlug-reviews___ru",
    path: "/ru/seller/:sellerSlug()/reviews",
    component: () => import("/app/pages/seller/[sellerSlug]/reviews/index.vue")
  },
  {
    name: "product-productName-productId___ua",
    path: "/product/:productName()/:productId()",
    component: () => import("/app/pages/product/[productName]/[productId]/index.vue")
  },
  {
    name: "product-productName-productId___ru",
    path: "/ru/product/:productName()/:productId()",
    component: () => import("/app/pages/product/[productName]/[productId]/index.vue")
  },
  {
    name: "pages-categorySlug-synthetic-associates___ua",
    path: "/pages/:categorySlug()/synthetic-associates",
    meta: synthetic_45associatesI_GhazYlnH8bk9FnKd5SJO5gA1IriFuUi2GgizMBROUMeta || {},
    component: () => import("/app/pages/pages/[categorySlug]/synthetic-associates.vue")
  },
  {
    name: "pages-categorySlug-synthetic-associates___ru",
    path: "/ru/pages/:categorySlug()/synthetic-associates",
    meta: synthetic_45associatesI_GhazYlnH8bk9FnKd5SJO5gA1IriFuUi2GgizMBROUMeta || {},
    component: () => import("/app/pages/pages/[categorySlug]/synthetic-associates.vue")
  },
  {
    name: "category-categorySlug-categoryId___ua",
    path: "/category/:categorySlug()/:categoryId()",
    component: () => import("/app/pages/category/[categorySlug]/[categoryId]/index.vue")
  },
  {
    name: "category-categorySlug-categoryId___ru",
    path: "/ru/category/:categorySlug()/:categoryId()",
    component: () => import("/app/pages/category/[categorySlug]/[categoryId]/index.vue")
  },
  {
    name: "product-productName-productId-create-review___ua",
    path: "/product/:productName()/:productId()/create-review",
    component: () => import("/app/pages/product/[productName]/[productId]/create-review.vue")
  },
  {
    name: "product-productName-productId-create-review___ru",
    path: "/ru/product/:productName()/:productId()/create-review",
    component: () => import("/app/pages/product/[productName]/[productId]/create-review.vue")
  },
  {
    name: "product-productName-productId-productCode___ua",
    path: "/product/:productName()/:productId()/:productCode()",
    component: () => import("/app/pages/product/[productName]/[productId]/[productCode]/index.ts")
  },
  {
    name: "product-productName-productId-productCode___ru",
    path: "/ru/product/:productName()/:productId()/:productCode()",
    component: () => import("/app/pages/product/[productName]/[productId]/[productCode]/index.ts")
  },
  {
    name: "category-categorySlug-categoryId-categoryFilters___ua",
    path: "/category/:categorySlug()/:categoryId()/:categoryFilters()",
    component: () => import("/app/pages/category/[categorySlug]/[categoryId]/[categoryFilters]/index.ts")
  },
  {
    name: "category-categorySlug-categoryId-categoryFilters___ru",
    path: "/ru/category/:categorySlug()/:categoryId()/:categoryFilters()",
    component: () => import("/app/pages/category/[categorySlug]/[categoryId]/[categoryFilters]/index.ts")
  },
  {
    name: "product-productName-productId-productCode-create-review___ua",
    path: "/product/:productName()/:productId()/:productCode()/create-review",
    component: () => import("/app/pages/product/[productName]/[productId]/[productCode]/create-review.ts")
  },
  {
    name: "product-productName-productId-productCode-create-review___ru",
    path: "/ru/product/:productName()/:productId()/:productCode()/create-review",
    component: () => import("/app/pages/product/[productName]/[productId]/[productCode]/create-review.ts")
  }
]