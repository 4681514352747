import { defineStore } from 'pinia'
import { useAuthUserStore } from './auth-user'
import type { MetricPayload } from '~/types/common'

interface Event extends MetricPayload {
  event_dateTime: string
}

interface State {
  events: Event[],
  sourceUrl: string
  userAgent: string
  timerId: null
}

export const useCommonMetricsStore = defineStore('commonMetricsStore', {
  state: (): State => ({
    events: [],
    sourceUrl: '',
    userAgent: '',
    timerId: null
  }),
  actions: {
    addMetricEvent(payload: MetricPayload) {
      const { $dayjs } = useNuxtApp()
      const { metricsEnabled } = useRuntimeConfig().public

      if (metricsEnabled) {
        const event = {
          ...payload,
          event_dateTime: $dayjs.utc().format()
        }

        this.events = [event, ...this.events]
      }
    },

    setSourceUrl(payload: string) {
      const { metricsEnabled } = useRuntimeConfig().public

      if (metricsEnabled) {
        this.sourceUrl = payload
      }
    },

    setUserAgent(payload: string) {
      const { metricsEnabled } = useRuntimeConfig().public

      if (metricsEnabled) {
        this.userAgent = payload
      }
    },

    sendMetrics() {
      const authUserStore = useAuthUserStore()
      const { $metricsApi } = useNuxtApp()
      const { metricsEnabled } = useRuntimeConfig().public

      if (metricsEnabled) {
        const events = [...this.events]

        if (events.length > 0) {
          const payload = {
            events: this.events,
            source: {
              sessionid: useCookie('sessionid').value,
              isAuthenticated: authUserStore.isAuthenticated,
              userId: authUserStore.data.uuid,
              url: this.sourceUrl,
              user_agent: this.userAgent
            },
            version: '1.0.22'
          }

          $metricsApi('/api/v1/events/', {
            method: 'POST',
            body: payload
          })

          this.events = []
        }
      }
    }
  }
})
