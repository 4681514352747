import posthog from 'posthog-js'

export default defineNuxtPlugin(() => {
  const { posthogEnabled, posthogKey } = useRuntimeConfig().public
  const sessionIdCookie = useCookie('sessionid')

  if (posthogEnabled) {
    posthog.init(posthogKey,
      {
        api_host: 'https://eu.i.posthog.com',
        capture_pageview: false,
        autocapture: false,
        disable_session_recording: true,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true
          }
        },
        loaded: () => posthog.identify(sessionIdCookie.value || '') // If you can already identify your user
      }
    )
  }

  // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
  return {
    provide: {
      posthog,
    },
  }
})