<template>
  <template v-if="error?.statusCode === 404">
    <BlogerLanding404 v-if="isLandingPage" />
    <Base404 v-else :is-from-product="isFromProduct" />
  </template>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

const route = useRoute()

const isLandingPage = computed(() => {
  return unref(route).path.includes('/l/')
})

const isFromProduct = computed(() => {
  return unref(route).path.includes('/product/')
})

onMounted(() => {
  const currentRoute = unref(route)
  const { params, query } = currentRoute

  if (checkRoutePage(currentRoute, 'product')) {
    const { productId } = params
  } else if (checkRoutePage(currentRoute, 'checkout')) {
    const orderUuid = query.order as string
  }
})
</script>

<style>

</style>