/* eslint-disable */
import type { ProductInfoForMsg } from '@/types/messenger'
import type { PayloadObj, StateList } from '~/types/common'
import type { Product } from '~/types/product'
import type { QueryParamsList } from '~/types/query'

export const lowerFirst = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const uppertFirst = (string: any) => {
  if (!string) {
    return ''
  }
  const str = String(string)
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isEmptyObj = (obj: object) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

export const hasPropValue = (prop: any) => {
  let hasProp: boolean = false

  if (prop === undefined || prop === null) {
    return hasProp
  }

  switch (typeof prop) {
    case 'string':
      hasProp = prop.length > 0
      break
    case 'number':
      hasProp = Number.isFinite(prop)
      break
    case 'boolean':
      hasProp = true
      break
    case 'object':
      hasProp = (Array.isArray(prop) && prop.length > 0) || !isEmptyObj(prop)
      break
  }
  return hasProp
}

export const createObjFromExisting = (
  keys: string[],
  payload: PayloadObj<any>
) => {
  if (typeof payload !== 'object' && isEmptyObj(payload)) {
    return false
  }

  const objData: PayloadObj<any> = {}
  let hasObjData: boolean = false

  for (const key of keys) {
    const propPayload: any = payload[key]

    if (hasPropValue(propPayload)) {
      objData[key] = propPayload

      if (!hasObjData) {
        hasObjData = true
      }
    }
  }

  return hasObjData ? objData : false
}

export const defaultState = <T>(fetching: boolean = false): StateList<T> => ({
  fetching: fetching,
  list: [],
  count: 0
})

export const defaultQueryParams = (
  limit: number,
  page?: number
): QueryParamsList => {
  const offset: number = page !== undefined ? (page - 1) * limit : 0

  return { offset, limit }
}

export const getProductInfoForMsg = (
  product: Product | null,
  offerCode: string | null
): ProductInfoForMsg | undefined => {
  if (!product || !product.uuid) {
    return undefined
  }

  return {
    name: product.name,
    slug: product.slug,
    p_code: product.p_code,
    code: offerCode || null,
    image: product.images?.[0] || ''
  }
}

export const isIos = () => {
  // @ts-ignore
  return /iPhone/.test(navigator.userAgent) && !window.MSStream
}

export const getAge = (dateString: string) => {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

export const getCityShortName = (name: string) => {
  return name.replace(/^[A-zА-я]{1,4}\.?\s/, '')
}

export const getOffset = (page: string | number | null | any, limit: number) => {
  const normalizePage = +(page || 1)

  return (normalizePage - 1) * limit
}

export const getCountFormated = (value: number) => {
  if (value < 1000) {
    return value.toString()
  } else if (value < 1000000) {
    return `${(value / 1000).toFixed(1)}K`
  } else {
    return `${(value / 1000000).toFixed(1)}M`
  }
}