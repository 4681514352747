import { useCheckoutStore } from '@/stores/checkout'
import type { Router } from 'vue-router'

const pagesAuth: Array<string | symbol> = [
  'auth-login___ua',
  'auth-login___ru',
  'auth-register___ru',
  'auth-register___ua'
]
const pageCheckoutComplete: Array<string | symbol> = [
  'checkout-complete___ru',
  'checkout-complete___ua'
]
const pageHome: Array<string | symbol> = ['index___ru', 'index___ua']

export default defineNuxtPlugin((nuxtApp) => {
  const $router = nuxtApp.$router as Router

  const commonMetricsStore = useCommonMetricsStore()
  const checkoutStore = useCheckoutStore()
  const checkoutShipmentStore = useCheckoutShipmentStore()
  const messengerStore = useMessengerStore()

  $router.beforeEach((to, from, next) => {
    // Metrics START
    if (import.meta.client && from.name !== null) {
      // sendMetrics only if we have from route name (skip direct transition)
      // Send metrics before change page because new events group set new source
      commonMetricsStore.sendMetrics()
    }
    // Metrics END

    if (to.query.chat_open === 'true') {
      messengerStore.initChat({
        actionName: 'chatWithModerator',
        blockLocation: 'email_trigger'
      })

      delete to.query.chat_open
    }

    if (
      to.name &&
      pagesAuth.includes(to.name) &&
      !to.query.next &&
      from.name &&
      !pageHome.includes(from.name) &&
      !pagesAuth.includes(from.name)
    ) {
      const hasFromName: boolean = from.name !== null
      let fullPath: string = ''

      if (hasFromName && pageCheckoutComplete.includes(from.name as string)) {
        fullPath = '/'
      } else if (hasFromName && pagesAuth.includes(from.name as string)) {
        fullPath = from.query.next as string
      } else if (from.params.lang) {
        fullPath = from.fullPath.replace(
          new RegExp(`/${from.params.lang}/?`),
          '/'
        )
      } else {
        fullPath = from.fullPath
      }

      next({
        path: to.fullPath,
        query: { next: fullPath }
      })
    } else {
      next()
    }
  })

  $router.afterEach((to, from) => {
    const fromName = from.name as string || ''
    const toName = to.name as string || ''

    if (
      fromName.includes('checkout') &&
      !(toName.includes('complete') || toName.includes('checkout'))
    ) {
      checkoutStore.clearCheckout()
      checkoutShipmentStore.clearCheckoutShipment()
    }

    // Open or close messenger when router hash is changed
    if (to.hash === '#m') {
      messengerStore.changeWidgetVisibility(true)
    } else if (messengerStore.showChat) {
      messengerStore.changeWidgetVisibility(false)
    }

    // Metrics START
    // Add setTimeout to add all events from preview page before set new source url
    setTimeout(() => {
      if (import.meta.client && from.name !== null) {
        // Send metrics before set new source url
        commonMetricsStore.sendMetrics()
        // Set new source url after change page
        commonMetricsStore.setSourceUrl(to.fullPath)
      }
    }, 0)
    // Metrics END
  })
})
