import { defineStore } from 'pinia'
// import { TOAST_OPTIONS_LOGOUT } from '~/constants/common'
import { useAuthUserStore } from './auth-user'
import { useWishlistStore } from './wishlist'
import { useMessengerStore } from './messenger'
import { TOAST_OPTIONS_LOGOUT } from '~/constants/common'
import type { ApiCheckPhoneExistResponse } from '~/types/api/api-auth.types'

interface AuthFormData {
  mobile_phone: string
  password: string
}

interface State {
  registration_token: string
  registration_uid: string
  registration_mobile_phone: string
  registration_form_data: AuthFormData
  reset_password_confirm_token: string
  reset_password_confirm_uid: string
  reset_password_confirm_mobile_phone: string
  new_password_token: string
  new_password_uid: string
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    registration_token: '',
    registration_uid: '',
    registration_mobile_phone: '',
    registration_form_data: {
      mobile_phone: '',
      password: ''
    },
    reset_password_confirm_token: '',
    reset_password_confirm_uid: '',
    reset_password_confirm_mobile_phone: '',
    new_password_token: '',
    new_password_uid: ''
  }),
  getters: {

  },
  actions: {
    async checkPhoneExist(mobilePhone: string) {
      const { $api } = useNuxtApp()

      return new Promise<boolean>((resolve, reject) => {
        $api<ApiCheckPhoneExistResponse>('/api/auth/register/defaults/mobile/', {
          method: 'POST',
          body: {
            mobile_phone: getPhoneNumber(mobilePhone)
          }
        })
        .then(({ success }) => {
          resolve(Boolean(success))
        })
        .catch(() => {
          resolve(false)
        })
      })
    },

    setData({ key, value }: { key: keyof Omit<State, 'registration_form_data'>, value: string }) {
      this[key] = value
    },

    setFormData(payload: AuthFormData) {
      this.registration_form_data = payload
    },

    logout() {
      const authUserStore = useAuthUserStore()
      const wishlistStore = useWishlistStore()
      const messengerStore = useMessengerStore()
      const localePath = useLocalePath()
      const { domain } = useRuntimeConfig().public
      const route = useRoute()

      // Define cookies with deletion options
      const authAccessCookie = useCookie('auth_access')
      const authRefreshCookie = useCookie('auth_refresh')
      const ageRestrictionCookie = useCookie('age_restriction')

      authUserStore.$reset()

      // Set cookie values to null
      authAccessCookie.value = null
      authRefreshCookie.value = null
      ageRestrictionCookie.value = null

      refreshCookie('auth_access')
      refreshCookie('auth_refresh')
      refreshCookie('age_restriction')

      document.cookie = 'auth_access=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'
      document.cookie = 'auth_refresh=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'
      document.cookie = 'age_restriction=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'

      const currentRoutePath = route.path
      const redirectInAuthRoutes = ['/profile', '/return']

      if (redirectInAuthRoutes.some((path) => currentRoutePath.includes(path))) {
        navigateTo(localePath('/auth/login'))
      }

      wishlistStore.resetState()

      messengerStore.changeWidgetVisibility(false)

      if (route?.hash) {
        navigateTo(localePath(route.path))
      }
    }
  }
})
