import { defineStore } from 'pinia'
import type { ApiGetCategoriesResponse } from '~/types/api/api-catalog.types'
import type { ServerListResponse } from '~/types/api/response.types'
import type { MenuCategoryItem } from '~/types/catalog'

interface State {
  list: MenuCategoryItem[]
  listMap: { [key: string]: MenuCategoryItem }
  fetching: boolean
}

export const useCommonCategoriesStore = defineStore('commonCategories', {
  state: (): State => ({
    list: [],
    listMap: {},
    fetching: false
  }),
  actions: {
    async getCategories(payload: number | null = null) {
      const { $api } = useNuxtApp()

      this.fetching = true

      const url = payload
        ? `/api/catalog/categories/?depth=${payload}`
        : '/api/catalog/categories/'


      return $api<ApiGetCategoriesResponse>(url)
        .then(({ results }) => {
          this.list = results || []

          if (payload === null) {
            const listMap: { [key: string]: MenuCategoryItem } = {}

            const setMap = (categories: MenuCategoryItem[]) => {
              categories.forEach((category) => {
                listMap[category.uuid] = category

                if (category.children && category.children.length > 0) {
                  setMap(category.children)
                }
              })
            }

            setMap(results || [])

            this.listMap = listMap
          }
        })
        .catch(() => {
          this.list = []
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }
})
