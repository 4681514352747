export default defineNuxtPlugin(() => {
  const commonCategoriesStore = useCommonCategoriesStore()
  const wishlistOffersStore = useWishlistOffersStore()
  const authUserStore = useAuthUserStore()

  if (authUserStore.isAuthenticated) {
    // get wishlist codes
    wishlistOffersStore.getWishlistOffersCodes()
  }

  // Fetch all menu categories
  commonCategoriesStore.getCategories()
})
