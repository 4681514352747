import { defineStore } from 'pinia'
import { TOAST_OPTIONS_CART } from '~/constants/common'
import { modalCartId, modalOffersErrorId } from '~/constants/modalNames'
import type { ApiGetCartResponse, ApiGetOrderIsAvailableResponse } from '~/types/api/api-carts.types'
import type { CartItem, CartOrder, OfferIsAvailable, Order, OrderItem, PayloadOrderAvailability, PayloadRemoveFromCartOffer, PayloadRestoreFromCartOffer, PayloadTempRemoveFromCartOffer } from '~/types/checkout'
import type { Product, ProductCatalog } from '~/types/product'

interface State {
  fetchingOrders: boolean
  orders: CartOrder[]
  updatingOffers: string[]
}

const addAdditionalInformationToOffers = (orders: Order[]): CartOrder[] => {
  return orders.map((order) => {
    return {
      ...order,
      items: order.items.map((orderItem) => {
        return {
          ...orderItem,
          additionalInfo: {
            isTempRemoved: false,
            isAvailable: true,
            priceOld: orderItem.price_unit,
            promoCodeAmount: null
          }
        }
      })
    }
  })
}

export const useCartStore = defineStore('cart', {
  state: (): State => ({
    fetchingOrders: false,
    orders: [],
    updatingOffers: []
  }),
  getters: {
    allAvailableCartItems(state) {
      return state.orders.reduce((results: CartItem[], order: CartOrder) => {
        return [
          ...results,
          ...order.items
            .filter((offer) => !offer.additionalInfo.isTempRemoved)
            .map((item) => {
              return {
                ...item,
                orderId: order.uuid
              }
            })
        ]
      }, [])
    },

    addedOffer() {
      return (offerId: string) => {
        return (
          this.allAvailableCartItems.findIndex((item) => item.uuid === offerId) !== -1
        )
      }
    },

    count(): number {
      return this.allAvailableCartItems.length
    },

    hasOffers(): boolean {
      return this.count > 0
    },

    total(): number {
      return this.allAvailableCartItems.reduce((total: number, cartItem: CartItem) => total + cartItem.price_total, 0)
    }
  },
  actions: {
    async getCart() {
      const { $api } = useNuxtApp()
      this.fetchingOrders = true

      $api<ApiGetCartResponse>('/api/carts/current/')
        .then((response) => {
          this.orders = addAdditionalInformationToOffers(response.orders)
        })
        .catch((e) => {
          this.orders = []
        })
        .finally(() => {
          this.fetchingOrders = false
        })
    },

    async updateCartOffer({ orderId, offerId, quantity }: { orderId: string, offerId: string, quantity: number }) {
      const checkoutStore = useCheckoutStore()
      const { $api } = useNuxtApp()
      this.updatingOffers.push(offerId)

      $api<OrderItem>(
        `/api/carts/current/orders/${orderId}/offers/${offerId}/`,
        {
          method: 'PATCH',
          body: {
            quantity
          }
        }
      )
        .then((response) => {
          checkoutStore.resetPromoCode()

          // Update order item quantity
          this.orders = this.orders.map((order) => {
            if (order.uuid === orderId) {
              return {
                ...order,
                items: order.items.map((orderItem) => {
                  if (orderItem.uuid === offerId) {
                    return {
                      ...orderItem,
                      ...response
                    }
                  } else {
                    return orderItem
                  }
                })
              }
            } else {
              return order
            }
          })
        })
        .finally(() => {
          this.updatingOffers = this.updatingOffers.filter(
            (uuid) => uuid !== offerId
          )
        })
    },

    tempRemoveCartOffer({ orderId, offerId }: PayloadTempRemoveFromCartOffer) {
      const order = this.orders.find((order) => order.uuid === orderId)

      if (order) {
        const offer = order.items.find((offer) => offer.uuid === offerId)

        if (offer) {
          offer.additionalInfo.isTempRemoved = true
        }
      }
    },

    restoreCartOffer({ orderId, offerId }: PayloadRestoreFromCartOffer) {
      const order = this.orders.find((order) => order.uuid === orderId)

      if (order) {
        const offer = order.items.find((offer) => offer.uuid === offerId)

        if (offer) {
          offer.additionalInfo.isTempRemoved = false
        }
      }

      this.checkingOrderAvailability({ orderId })
    },

    async removeOffersFromOrder(payload: PayloadRemoveFromCartOffer[]) {
      const { $api, $toast, $i18n } = useNuxtApp()
      const orderId = payload[0].orderId
      const offersId = payload.map((offer) => offer.offerId)

      $api(
        `/api/carts/current/orders/${orderId}/items/`,
        {
          method: 'DELETE',
          body: {
            items: offersId
          }
        }
      )
        .then(() => {
          const order = this.orders.find((order) => order.uuid === orderId)

          if (order) {
            order.items = order.items.filter(
              (offer) => !offersId.includes(offer.uuid)
            )
          }
        })
        .catch((e) => {
          if (e.response.status !== 404) {
            $toast.error($i18n.t('cart.delete_error'))
          }
        })
    },

    async removeOrder({ orderId, order }: { orderId: string, order: CartOrder }) {
      const { $api, $toast, $i18n } = useNuxtApp()

      $api<{ count: number, orders: CartOrder }>(`/api/carts/current/orders/${orderId}/`, {
        method: 'DELETE'
      })
        .then(() => {
          this.orders = this.orders.filter((orderItem) => orderItem.uuid !== orderId)

          const items = order.items.filter(
            (item) => item.seller.slug !== 'galactic-empire'
          )
        })
        .catch(() => {
          $toast.error($i18n.t('cart.delete_error'))
        })
    },

    confirmTempRemoveCartOffer() {
      for (const order of this.orders) {
        const allOffersTempRemoved = order.items.every((item) => item.additionalInfo.isTempRemoved)

        if (allOffersTempRemoved) {
          this.removeOrder({ orderId: order.uuid, order })
        } else {
          const removeOffers = order.items
            .filter((item) => item.additionalInfo.isTempRemoved)
            .map((item) => ({
              orderId: order.uuid,
              offerId: item.uuid,
              product: item.product
            }))

          if (removeOffers.length > 0) {
            this.removeOffersFromOrder(removeOffers)
          }
        }
      }
    },

    checkingOrderAvailability({ orderId }: PayloadOrderAvailability) {
      const { $api } = useNuxtApp()

      return new Promise<{ allOffersAvailable: boolean, hasChangedPrice: boolean, hasChangedQuontityMax: boolean }>((resolve, reject) => {
        $api<ApiGetOrderIsAvailableResponse>(
          `/api/carts/current/orders/${orderId}/is-available/`
        ).then(({ results }) => {
          let hasChangedPrice = false
          let hasChangedQuontityMax = false

          this.orders = this.orders.map((order) => {
            return order.uuid === orderId
              ? {
                  ...order,
                  items: order.items.map((orderItem) => {
                    const newItemData = results.find((item) => item.uuid === orderItem.uuid)

                    // Check if some offer in order has new price
                    if (!hasChangedPrice && newItemData) {
                      hasChangedPrice = orderItem.price_unit !== newItemData.price
                    }

                    if (!hasChangedQuontityMax && newItemData) {
                      hasChangedQuontityMax = orderItem.quantity_max < newItemData.quantity_max
                    }

                    return newItemData
                      ? {
                          ...orderItem,
                          additionalInfo: {
                            ...orderItem.additionalInfo,
                            isAvailable: newItemData.is_available // Set is available status
                          },
                          quantity_max: newItemData.quantity_max, // Set new quantity max
                          price_unit: newItemData.price // Set new price
                        }
                      : orderItem
                  })
                }
              : order
          })

          const allOffersAvailable = results.every((offer) => {
            return offer.is_available
          })

          resolve({
            allOffersAvailable,
            hasChangedPrice,
            hasChangedQuontityMax
          })
        }).catch((e) => {
          reject(e)
        })
      })
    },

    addPromoCodeDiscountToOrderItems({ orderId, results }: { orderId: string, results: OfferIsAvailable[] }) {
      this.orders = this.orders.map((order) => {
        return order.uuid === orderId
          ? {
              ...order,
              items: order.items.map((orderItem) => {
                const newItemData = results.find((item) => item.uuid === orderItem.uuid)

                return newItemData
                  ? {
                      ...orderItem,
                      additionalInfo: {
                        ...orderItem.additionalInfo,
                        promoCodeAmount: newItemData.promo_code_amount // Set is promo code discount
                      }
                    }
                  : orderItem
              })
            }
          : order
      })
    },

    resetPromoCodeDiscount(orderId: string) {
      this.orders = this.orders.map((order) => {
        return order.uuid === orderId
          ? {
              ...order,
              items: order.items.map((orderItem) => {
                return {
                  ...orderItem,
                  additionalInfo: {
                    ...orderItem.additionalInfo,
                    promoCodeAmount: null // Reset promo code discount
                  }
                }
              })
            }
          : order
      })
    },

    addToCart(payload: { offerId: string, offerCode: string, offerName: string, price: number, categoryName: string, showCart?: boolean }) {
      const { offerId, offerCode, offerName, price, categoryName, showCart = true } = payload
      const { $api, $vfm, $toast, $i18n, $gtm } = useNuxtApp()

      return new Promise<CartOrder[]>((resolve, reject) => {
        $api<ApiGetCartResponse>('/api/carts/current/orders/', {
          method: 'PUT',
          body: {
            offer: offerId
          }
        })
          .then((response) => {
            const updatedOrders = addAdditionalInformationToOffers(response.orders)

            this.orders = updatedOrders

            showCart
              ? $vfm.open(modalCartId)
              : $toast.success($i18n.t('cart.added_to_cart'), {
                ...TOAST_OPTIONS_CART,
                onClick: () => $vfm.open(modalCartId)
              })

            $gtm.addToCart({
              id: offerCode,
              name: offerName,
              categoryName: categoryName,
              price,
              quantity: 1
            })

            resolve(updatedOrders)
          })
          .catch((e: any) => {
            if (e.response.status === 400) {
              if (e.response.data.offer) {
                $vfm.open(modalOffersErrorId)
              }
            } else {
              reject(e)
            }
          })
      })
    },

    async addToCheckout(offerId: string) {
      const { $api, $vfm } = useNuxtApp()

      return new Promise<CartOrder[]>((resolve, reject) => {
        $api<{ uuid: string, orders: Order[] }>('/api/carts/current/orders/', {
          method: 'PUT',
          body: {
            offer: offerId
          }
        })
          .then((response) => {
            const updatedOrders = addAdditionalInformationToOffers(response.orders)
  
            this.orders = updatedOrders
  
            resolve(updatedOrders)
          })
          .catch((e: any) => {
            if (e.response.status === 400) {
              if (e.response.data.offer) {
                $vfm.open(modalOffersErrorId)
              }
            } else {
              reject(e)
            }
          })
      })
    },

    clearCartOrders(orderUuid: string) {
      this.orders = this.orders.filter((order) => order.uuid !== orderUuid)
    }
  }
})
