import type { ServerListResponse } from '~/types/api/response.types'
import type { ProductCatalog } from '~/types/product'

export const useWishlistStore = defineStore('wishlist', () => {
  const { $api } = useNuxtApp()

  const itemsFetching = shallowRef(false)
  const productsCount = shallowRef(0)
  const productsItems = ref<ProductCatalog[]>([])
  const productsAmount = shallowRef(0)

  const getWishlistOffers = async ({ offset, limit }: { offset: number, limit: number }) => {
    itemsFetching.value = true

    return new Promise((resolve, reject) => {
      $api<ServerListResponse<ProductCatalog, { amount: number }>>(
        `/api/users/profile/wishlists/offers/?limit=${limit}&offset=${offset}`
      )
        .then((response) => {
          productsCount.value = response.count
          productsItems.value = response.results.map((product) => markRaw(product))
          productsAmount.value = response.meta.amount

          resolve(response)
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          itemsFetching.value = false
        })
    })
  }

  const removeFromWishlist = (offerCode: string)=> {
    const indexToRemove = productsItems.value.findIndex((item: ProductCatalog) => {
      if (item.offer_main.code === offerCode) {
        productsAmount.value -= item.offer_main.price
        return true
      } else {
        return false
      }
    })
    productsItems.value.splice(indexToRemove, 1)

    if (productsCount.value !== 0) {
      productsCount.value -= 1
    }

    $api(`api/users/profile/wishlists/${offerCode}/`, {
      method: 'DELETE'
    })
  }

  const resetState = () => {
    itemsFetching.value = false
    productsCount.value = 0
    productsItems.value = []
    productsAmount.value = 0
  }

  return {
    itemsFetching,
    productsCount,
    productsItems,
    productsAmount,
    getWishlistOffers,
    removeFromWishlist,
    resetState
  }
})
