import { defineStore } from 'pinia'
import { useWishlistOffersStore } from './wishlist-offers'
import { getAge } from '~/utils'
import { AVATAR_MODERATION_STATUS_EXPECTS, AVATAR_MODERATION_STATUS_FAILED } from '~/constants/common'
import type { UpdateUserPayload, UserData } from '~/types/auth'
import type { ApiBonusBillResponse } from '~/types/api/api-auth.types'

interface State {
  fetching: boolean
  isAuthenticated: boolean
  data: UserData,
  ageRestrictions: boolean | null,
  uploadedAvatar: string,
  selectedDiscount: number
}

const defaultData = {
  avatar: null,
  avatar_moderation_status: null,
  bill: {
    is_active: false,
    balance: 0,
    reserved_balance: 0
  },
  birthday: null,
  email: '',
  first_name: '',
  gender: null,
  is_confirmed_email: false,
  is_confirmed_mobile_phone: false,
  is_mailing_subscribed: false,
  is_referrals_available: false,
  last_name: '',
  living_city: null,
  middle_name: '',
  mobile_phone: '',
  referral_id: null,
  seller: null,
  username: '',
  uuid: ''
}

export const useAuthUserStore = defineStore('authUser', {
  state: (): State => ({
    fetching: false,
    isAuthenticated: false,
    data: { ...defaultData },
    ageRestrictions: null,
    uploadedAvatar: '',
    selectedDiscount: 0
  }),
  getters: {
    isReferralsAvailable(state) {
      return state.data.is_referrals_available
    },
    isConfirmedEmail(state) {
      return state.data.is_confirmed_email
    },
    userName(state) {
      return state.data.username || state.data.first_name || ''
    },
    userUuid(state) {
      return state.data.uuid
    },
    fullName(state) {
      return [state.data.last_name, state.data.first_name, state.data.middle_name]
        .filter((field) => field)
        .join(' ')
    },
    hasUserData(state) {
      return !!(
        state.data.email &&
        (state.data.username || state.data.first_name)
      )
    },
    bonusesBalance(state) {
      return state.data.bill?.is_active
        ? state.data.bill.balance || 0
        : 0
    },
    bonusesReserved(state) {
      if (state.data.bill?.is_active) {
        return state.data.bill.reserved_balance || 0
      }
      return 0
    },
    failedAvatar(state) {
      return (
        state.data.avatar?.moderation_status === AVATAR_MODERATION_STATUS_FAILED
      )
    },
    avatar(state) {
      const avatar = state.data.avatar

      if (state.uploadedAvatar) {
        return state.uploadedAvatar
      } else if (avatar?.img && !this.failedAvatar) {
        return avatar.img
      }

      return null
    },
    livingCity(state) {
      return state.data.living_city || null
    },
    userSeller(state) {
      return state.data.seller
    },
    hasUserSeller(state) {
      return !!state.data.seller
    },
    parsedMobile(state) {
      const mobilePhone = state.data.mobile_phone

      return !mobilePhone ? '' : parsedMobilePhone(mobilePhone)
    },
    userEmail(state) {
      return state.data?.email || ''
    }
  },
  actions: {
    async getUser(authToken?: string) {
      const { $api } = useNuxtApp()
      const ageRestrictionCookie = useCookie('age_restriction')

      const wishlistOffersStore = useWishlistOffersStore()

      this.fetching = true

      return new Promise<UserData>((resolve, reject) => {
        $api<UserData>('/api/users/profile/', {
          headers: {
            ...(authToken ? { Authorization: `Bearer ${authToken}` } : {})
          }
        })
          .then((response) => {
            this.data = {
              ...this.data,
              ...response
            }
            this.isAuthenticated = true
            this.selectedDiscount = 0

            this.checkAgeRestrictions(response.birthday)

            resolve(response)
          })
          .catch(() => {
            this.data = defaultData
            this.isAuthenticated = false

            // Set age restriction from cookies for anonymous user.
            if (typeof ageRestrictionCookie.value === 'boolean' && ageRestrictionCookie.value === false) {
              this.ageRestrictions = false
            }

            resolve(defaultData)
          })
          .finally(() => {
            this.fetching = false
          })
      })
    },

    checkAgeRestrictions(payload: string | null) {
      if (payload !== null && getAge(payload) >= 18) {
        this.ageRestrictions = false
      } else {
        this.ageRestrictions = true
      }
    },

    setAgeRestrictions(payload: boolean) {
      this.ageRestrictions = payload
    },

    async getBill() {
      if (this.isAuthenticated) {
        const { $api } = useNuxtApp()

        return new Promise<ApiBonusBillResponse>((resolve, reject) => {
          $api<ApiBonusBillResponse>('/api/users/profile/bonuses/bill/')
            .then((response) => {
              this.data = {
                ...this.data,
                bill: {
                  balance: response.bonuses,
                  is_active: response.is_active,
                  reserved_balance: response.bonuses_reserved
                }
              }

              this.selectedDiscount = 0
              resolve(response)
            })
            .catch((e) => {
              this.data = {
                ...this.data,
                bill: {
                  balance: 0,
                  is_active: false,
                  reserved_balance: 0
                }
              }

              reject(e)
            })
        })
      }
    },

    async activateReferral() {
      const { $api } = useNuxtApp()

      return new Promise<boolean>((resolve, reject) => {
        $api<UserData>('/api/users/profile/', {
          method: 'PATCH',
          body: {
            is_referrals_available: true
          }
        }).then((response) => {
          resolve(response.is_referrals_available)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    async updateUserData(payload: UpdateUserPayload) {
      const { $api } = useNuxtApp()

      return new Promise<UserData>((resolve, reject) => {
        $api<UserData>('/api/users/profile/', {
          method: 'PATCH',
          body: payload
        }).then((response) => {
          this.checkAgeRestrictions(response.birthday)

          this.data = {
            ...this.data,
            ...response
          }
          resolve(response)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    async updateEmail(payload: string) {
      const { $api } = useNuxtApp()

      return $api<Partial<UserData>>('/api/users/profile/email/', {
        method: 'PATCH',
        body: {
          email: payload
        }
      }).then(() => {
        this.data = {
          ...this.data,
          email: payload,
          is_confirmed_email: false
        }
      })
    },

    updateMobilePhone(payload: string) {
      this.data.mobile_phone = payload
    },

    setSelectedDiscount(payload: number) {
      this.selectedDiscount = payload
    },

    updateUserAvatar({ name, uploadName }: { name: string, uploadName: string }) {
      this.data = {
        ...this.data,
        avatar: {
          ...this.data.avatar,
          img: name,
          moderation_status: AVATAR_MODERATION_STATUS_EXPECTS
        }
      }
      this.uploadedAvatar = uploadName
    },

    setUserName(payload: string) {
      this.data.username = payload
    }
  }
})
