import type { RouterConfig } from '@nuxt/schema'
import {checkRoutePage} from '~/utils/checkRouteLocation'

// Ref: https://nuxt.com/docs/guide/recipes/custom-routing#router-options
export default <RouterConfig>{
  scrollBehavior: (to, from, savedPosition) => {
    if (to.params?.stop) {
      return
    }
    const pageNameStopScroll = [
      'seller',
      'bonuses',
      'search',
      'tags'
    ]

    // Scroll to top of window
    if (
      to.path === from.path && (to.hash === '#m' || from.hash === '#m') ||
      to.params.categoryId && to.params.categoryId === from.params.categoryId ||
      to.name === from.name && pageNameStopScroll.some((key) => checkRoutePage(to, key))
    ) {
      return
    }

    if (to.hash) {
      const el = document.querySelector(to.hash)
      // vue-router does not incorporate scroll-margin-top on its own.
      if (el) {
        return {
          el: to.hash,
          behavior: 'smooth',
          top: _getHashElementScrollMarginTop(to.hash)
        }
      }

      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }

    if (savedPosition) {
      return savedPosition
    }

    return { top: 0, left: 0 }
  }
}

function _getHashElementScrollMarginTop(selector: string): number {
  try {
    const elem = document.querySelector(selector)
    if (elem) {
      return (Number.parseFloat(getComputedStyle(elem).scrollMarginTop) || 0) + (Number.parseFloat(getComputedStyle(document.documentElement).scrollPaddingTop) || 0)
    }
  } catch {
    // ignore any errors parsing scrollMarginTop
  }
  return 0
}
