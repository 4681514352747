export { default as IMAGE_NOT } from '@/assets/images/notimg.svg'
export { default as IMAGE_FEMALE } from '@/assets/icons/avatar-female.svg'
export { default as IMAGE_MALE } from '@/assets/icons/avatar-male.svg'
export { default as IMAGE_CATEGORY_PLACEHOLDER } from '@/assets/images/category-placeholder.png'

export const DEFAULT_LOCALE = 'ru'
// export const IMAGE_DEFAULT = buildURL('default', process.env.NUXT_ENV_CDN_URL)

export const DELIVERY_PICKUP = 'DC300'
export const DELIVERY_DEPARTMENT = 'DC200'
export const DELIVERY_SYNTHETIC_DEPARTMENT = 'DC201'
export const DELIVERY_COURIER = 'DC100'
export const DELIVERY_COURIER_SELLER = 'DC101'

export const FLOATING_SCHEDULE: 'FLOATING_SCHEDULE' = 'FLOATING_SCHEDULE'
export const STANDARD_SCHEDULE: 'STANDARD_SCHEDULE' = 'STANDARD_SCHEDULE'

export const LANG_CODES = {
  ua: 'uk',
  ru: 'ru',
  en: 'en'
}

export const CITY_LIMIT = 25
export const DEPARTMENT_LIMIT = 25
export const OFFERS_LIMIT = 10
export const RED_OFFERS_LIMIT = 16

export const INIT_USER_PROGRESS__DATA = 20

export const CATALOG_LIMIT = 40

export const PRODUCT_REVIEWS_LIMIT = 10
export const SELLER_REVIEWS_LIMIT = 10
export const PROFILE_BONUSES_TRANSACTIONS_LIMIT = 5
export const PROFILE_REVIEWS_LIMIT = 10
export const PROFILE_RETURN_LIMIT = 10

export const REFERRAL_PRODUCTS_LIMIT = 15
export const REFERRAL_SELLERS_LIMIT = 5
export const REFERRAL_ORDERS_LIMIT = 5

export const TIME_CORRECTION = 60

export const SELLER_SUBSCRIBERS_LIMIT = 20

export const PRICE_GROUP_FILTER_CODE = 'fg000000'
export const BRAND_GROUP_FILTER_CODE = 'fp00004'
export const FREE_DELIVERY_FILTER_CODE = 'fo00006'
export const PRICE_FILTER_CODE = 'fo00000'
export const IN_STOCK_FILTER_CODE = 'fo00007'
export const BONUSES_FILTER_CODE = 'fo00002'
export const CONDITION_FILTER_CODE = 'fo00003'
export const CITY_FILTER_CODE = 'fo00005'
export const SALE_FILTER_CODE = 'fo00008'
export const CONDITION_FILTER_NEW_UUID = '16486455324f4ab5a0c57f27fae7058c'

export const CITY_FILTER_KEY = 'city-f'
export const CITY_HOME_KEY = 'city-home'

export const OFFER_FILTERS = [
  IN_STOCK_FILTER_CODE, // в наявності
  FREE_DELIVERY_FILTER_CODE, // безкоштовна доставка
  BONUSES_FILTER_CODE, // бонуси
  CONDITION_FILTER_CODE, // стан,
  CITY_FILTER_CODE // місто
]
export const FILTER_SPLIT_KEY = /&amp;|&/g

export const DEFAULT_TIMER_DISTANCE = 60

export const MAX_PAGES_SHOW_MORE = 3

export const SESSION_STORAGE_RETURN_COMPLETED_KEY = 'return_completed'

export const AVATAR_MODERATION_STATUS_FAILED = 'MODERATION_FAILED'
export const AVATAR_MODERATION_STATUS_EXPECTS = 'EXPECTS_MODERATION'

export const TOAST_OPTIONS_LOGIN = {
  icon: 'icon-login'
}

export const TOAST_OPTIONS_LOGOUT = {
  icon: 'icon-logout'
}

export const TOAST_OPTIONS_CART = {
  icon: 'icon-cart'
}

export const TOAST_OPTIONS_BALANCE = {
  icon: 'icon-balance'
}

export const TOAST_OPTIONS_REVIEWS_SOLID = {
  icon: 'icon-reviews-solid'
}

export const TOAST_OPTIONS_CASH_BACK = {
  icon: false,
  toastClassName: 'toast-cash-back'
}

export const TOAST_OPTIONS_OFFLINE = {
  id: 'offLine'
}

export const TOAST_OPTIONS_ONLINE = {
  id: 'onLine'
}

export const TOAST_OPTIONS_NEW_MESSAGE = (onClickHandler: () => void) => ({
  icon: 'icon-message',
  onClick: onClickHandler
})

export const DEFAULT_WEEK = () => {
  return {
    MON: { day: 'MON' },
    TUE: { day: 'TUE' },
    WED: { day: 'WED' },
    THU: { day: 'THU' },
    FRI: { day: 'FRI' },
    SAT: { day: 'SAT' },
    SUN: { day: 'SUN' }
  }
}

export const OFFERS_VISIBILITY_VALUES = {
  HIDDEN: 'HIDDEN',
  ALL: 'ALL'
}

export const KEY_COOKIES_USER_FIRST_ENTRY = 'userFirstEntry'

export const FREE_PRODUCT_DELIVERY_UIID = '282243bc6a914f259233d22cef4932db'

export const ORDER_COMPLETED_KEY = 'orderCompleted'

export const API_INTEGRATION_MONO = 'MONO'
export const API_INTEGRATION_MONO_PARTS = 'MONO_PARTS'
export const API_INTEGRATION_PRIVAT_HIRE_PURCHASE = 'PRIVAT_HIRE_PURCHASE'

export const PAYMENT_STATUS_AWAITING_PAYMENT = 'AWAITING_PAYMENT'
export const PAYMENT_STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED'
export const PAYMENT_STATUS_CANCELED_PAYMENT = 'CANCELED_PAYMENT'
export const PAYMENT_STATUS_PAID = 'PAID'
export const PAYMENT_STATUS_REFUND_ACCEPTED = 'REFUND_ACCEPTED'
export const PAYMENT_STATUS_REFUND_MANUALLY = 'REFUND_MANUALLY'
export const PAYMENT_STATUS_REFUND_CANCELED = 'REFUND_CANCELED'

export const ORDER_STATUS_NEW = 'NEW'
export const ORDER_STATUS_IN_PROCESSING = 'IN_PROCESSING'
export const ORDER_STATUS_SELLER_APPROVED = 'SELLER_APPROVED'
export const ORDER_STATUS_SHIPPED = 'SHIPPED'
export const ORDER_STATUS_SUCCESSFULLY_CLOSED = 'SUCCESSFULLY_CLOSED'
export const ORDER_STATUS_CANCELED_BY_SELLER = 'CANCELED_BY_SELLER'
export const ORDER_STATUS_CANCELED_BY_CUSTOMER = 'CANCELED_BY_CUSTOMER'
export const ORDER_STATUS_CANCELED_BY_SUPERVISOR = 'CANCELED_BY_SUPERVISOR'
export const ORDER_STATUS_RETURN_CLOSED = 'CLOSED'

export const SYNTHETIC_DELIVERY_METHOD = '918402e8f3c049c8968ae21fc197ca75'
export const NOVAPOSHTA_DELIVERY_METHOD = 'f8cc45af23fc45fca7bff82a7acd9955'
export const UKRPOSHTA_DELIVERY_METHOD = 'fd9261831d004395a4a760f5d332dd48'
export const MIST_DELIVERY_METHOD = '7dd6703c988747d4979e2de04258ccaa'

export const CITY_LUTSK_UUID = '3b5387c4-ff0d-4ba1-89bc-661871e56abd'
export const CITY_KYIV_UUID = '907428b6a2d04c8db74eef5313d09e83'

export const AMAZON_DELIVERY_UUID = '92b19f1cc5924791b50b7de10aaf9625'
