export default defineNuxtPlugin((nuxtApp) => {
  const { $metricsApi } = useNuxtApp()
  const { domain, metricsEnabled } = useRuntimeConfig().public
  const route = useRoute()
  const router = useRouter()
  const commonMetricsStore = useCommonMetricsStore()
  const metricsToken = useCookie('metrics_token', {
    path: '/',
    domain: domain as string,
    sameSite: 'lax'
  })

  if (metricsEnabled) {
    // Set metrics source url
    commonMetricsStore.setSourceUrl(route.fullPath)
    // Set metrics userAgent
    commonMetricsStore.setUserAgent(window.navigator.userAgent)
    // Set page enter event
    commonMetricsStore.addMetricEvent({
      element: '',
      event_type: 'page_enter',
      event_content: ''
    })

    // get and set metrics token if not exist
    if (!metricsToken.value) {
      $metricsApi<{ token: string }>('/api/v1/identity/', {
        method: 'POST'
      }).then((response) => {
        metricsToken.value = response.token

        refreshCookie('metrics_token')
      })
    }

    // Send metrics every 10s
    setInterval(() => {
      commonMetricsStore.sendMetrics()
    }, 10000)

    // Send metrics when visibility hidden and add event when visibility changed
    window.addEventListener('visibilitychange', () => {
      // Set page leave event
      if (document.visibilityState === 'hidden') {
        commonMetricsStore.addMetricEvent({
          element: '',
          event_type: 'page_focus_leave',
          event_content: ''
        })

        commonMetricsStore.sendMetrics()
      } else {
        commonMetricsStore.addMetricEvent({
          element: '',
          event_type: 'page_focus',
          event_content: {
            // cs_code: store.state?.product?.info?.offer_main?.code || ''
          }
        })
      }
    })

    router.beforeEach((to, from, next) => {
      if (from.name !== null) {
        // sendMetrics only if we have from route name (skip direct transition)
        // Send metrics before change page because new events group set new source
        commonMetricsStore.sendMetrics()
      }

      next()
    })

    router.afterEach((to, from) => {
      // Set source url when we open first page
      commonMetricsStore.setSourceUrl(to.fullPath)

      if (from.name !== null && from.fullPath !== to.fullPath) {
        commonMetricsStore.addMetricEvent({
          element: 'ms[page]',
          event_type: 'page_change',
          event_content: {
            prev_page: from.fullPath,
            next_page: to.fullPath
          }
        })
      }
    })
  }
})
