import type { SearchCity, SearchCityDefault } from '~/types/common'

interface UnsubscribePayload {
  token: string
  message: string
  email: string
}

export const useCommonStore = defineStore('common', () => {
  const { $api, $toast, $i18n } = useNuxtApp()

  const {
    handleAutoDelay,
    clearDelay,
    delayActive
  } = useDelay(false)

  const isFaviconMsg = shallowRef(false)
  const citiesDefault = shallowRef<SearchCityDefault[]>([])

  const isIconPrefix = computed(
    () => isFaviconMsg.value ? 'm-' : ''
  )

  const getCityDefault = (checkHasValue = false) => {
    if (checkHasValue && citiesDefault.value.length) {
      return
    }

    return $api<SearchCity[]>('/api/common/default-cities/')
      .then((results) => {
        citiesDefault.value = results.map((city) => ({
          ...city,
          short_name: getCityShortName(city.name)
        }))
      })
      .catch(() => {
        citiesDefault.value = []
      })
  }

  const cancelSubscribe = async ({ token, message, email }: UnsubscribePayload)  => {
    try {
      const result = await $api<{ success: boolean }>(`/api/common/unsubscribe/${encodeURIComponent(token)}/`, {
        method: 'POST',
        body: {
          message,
          email
        }
      })

      if (result.success) {
        $toast.success($i18n.t('user.cancel_subscribe_success'))
      } else {
        $toast.error($i18n.t('user.cancel_subscribe_failure'))
      }
    } catch (e) {
      $toast.error($i18n.t('user.cancel_subscribe_failure'))
      throw e
    }
  }

  const checkUnreadMsg = (count: number) => {
    if (count > 0) {
      if (!delayActive) {
        handleAutoDelay(() => {
          isFaviconMsg.value = !isFaviconMsg.value
        }, 2000)
      }
      return
    }

    clearDelay()
    isFaviconMsg.value = false
  }

  return {
    isFaviconMsg,
    citiesDefault,
    isIconPrefix,
    getCityDefault,
    cancelSubscribe,
    checkUnreadMsg
  }
})