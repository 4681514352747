export function useFaviconNotification(title: ComputedRef<string>) {
  const messengerStore = useMessengerStore()
  const commonStore = useCommonStore()
  const { t } = useI18n()

  const isFaviconMsg = computed<boolean>(() => {
    return commonStore.isFaviconMsg
  })

  const unreadMessages = computed<number>(() => {
    return messengerStore.unreadCount
  })

  watch(unreadMessages, (count) => {
    commonStore.checkUnreadMsg(count)
  })

  const headTitle = computed(() => {
    return (isFaviconMsg.value
      ? t('messenger.unread', { count: unreadMessages.value })
      : unref(title)).toString()
  })

  return {
    headTitle
  }
}