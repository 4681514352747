/**
 * Хук для створення та управління AbortController.
 * Забезпечує можливість скасування запитів та обробки сигналів скасування.
 */
export const useAbortController = () => {
  // Контролер для скасування запитів
  let controller: AbortController | null = null

  const checkActiveAbortSignal = () => {
    return controller && !controller.signal.aborted
  }

  const abortController = () => {
    if (checkActiveAbortSignal()) {
      controller!.abort('Not actual')
    }
  }

  /**
   * Створює новий AbortController або скасовує попередній.
   * @returns Об'єкт з властивістю `signal`, яку можна використовувати для передачі в запити.
   */
  const getAbortController = () => {
    // Якщо є активний контролер, скасовуємо його
    abortController()
    // Створюємо новий AbortController
    controller = new AbortController()

    return {
      signal: controller.signal
    }
  }

  const isErrorAbort = (error: any) => {
    return error.name === 'AbortError' || error.cause?.name === 'AbortError'
  }


  return {
    getAbortController,
    abortController,
    checkActiveAbortSignal,
    isErrorAbort
  }
}
