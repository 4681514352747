// plugins/intersect.ts (створення директиви)
import type { DirectiveBinding } from 'vue'

interface IntersectOptions {
  threshold?: number | number[]
  rootMargin?: string
  root?: Element | null
  once?: boolean
  enter?: (entry: IntersectionObserverEntry) => void
  leave?: (entry: IntersectionObserverEntry) => void
  change?: (entry: IntersectionObserverEntry) => void
}

export default defineNuxtPlugin((nuxtApp) => {
  const observers = new WeakMap<HTMLElement, IntersectionObserver>()

  nuxtApp.vueApp.directive('intersect', {
    mounted(el: HTMLElement, binding: DirectiveBinding<IntersectOptions>) {
      const {
        threshold = 0.33,
        rootMargin = '0px',
        root = null,
        once = true,
        enter,
        leave,
        change
      } = binding.value || {}

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // Call change handler if provided
            change?.(entry)

            if (entry.isIntersecting) {
              // Call enter handler if provided
              enter?.(entry)
              
              // Dispatch custom event
              el.dispatchEvent(new CustomEvent('intersect:enter', { detail: entry }))

              // If once is true, disconnect after first intersection
              if (once) {
                observer.disconnect()
              }
            } else {
              // Call leave handler if provided
              leave?.(entry)
              
              // Dispatch custom event
              el.dispatchEvent(new CustomEvent('intersect:leave', { detail: entry }))
            }
          })
        },
        { threshold, rootMargin, root }
      )

      observer.observe(el)
      observers.set(el, observer)
    },

    updated(el: HTMLElement, binding: DirectiveBinding<IntersectOptions>) {
      // Reconnect observer if options changed
      const oldObserver = observers.get(el)
      if (oldObserver) {
        oldObserver.disconnect()
        observers.delete(el)
      }
      
      // Recreate with new options
      const {
        threshold = 0.33,
        rootMargin = '0px',
        root = null,
        once = false,
        enter,
        leave,
        change
      } = binding.value || {}

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            change?.(entry)

            if (entry.isIntersecting) {
              enter?.(entry)
              el.dispatchEvent(new CustomEvent('intersect:enter', { detail: entry }))
              if (once) {
                observer.disconnect()
              }
            } else {
              leave?.(entry)
              el.dispatchEvent(new CustomEvent('intersect:leave', { detail: entry }))
            }
          })
        },
        { threshold, rootMargin, root }
      )

      observer.observe(el)
      observers.set(el, observer)
    },

    unmounted(el: HTMLElement) {
      const observer = observers.get(el)
      if (observer) {
        observer.disconnect()
        observers.delete(el)
      }
    }
  })
})
