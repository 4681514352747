import { defineStore } from 'pinia'
import { useCommonMetricsStore } from './common-metrics'
import { MIST_DELIVERY_METHOD } from '~/constants/common'
import { getDeliveryService } from '~/utils/checkout'
import type { Department } from '~/types/delivery'
import type { ServerListResponse } from '~/types/api/response.types'
import type { SearchCity } from '~/types/common'
import type { QueryParams } from '~/types/query'

interface State {
  departments: {
    list: Department[]
    count: number
    fetching: boolean
  },
  cities: {
    list: SearchCity[]
    count: number
    fetching: boolean
  }
}

interface GetDepartmentsPayload {
  deliveryMethodUuid: string
  cityUuid: string
  queryParams: QueryParams
  isUpdate?: boolean
}

const {
  getAbortController,
  isErrorAbort
} = useAbortController()

export const useCheckoutMetaStore = defineStore('checkoutMeta', {
  state: (): State => ({
    departments: {
      list: [],
      count: 0,
      fetching: false
    },
    cities: {
      list: [],
      count: 0,
      fetching: false
    }
  }),
  getters: {

  },
  actions: {
    async getDepartments({ deliveryMethodUuid, cityUuid, queryParams, isUpdate = false }: GetDepartmentsPayload) {
      const commonMetricsStore = useCommonMetricsStore()
      const { $api } = useNuxtApp()
      const { signal } = getAbortController()

      if (deliveryMethodUuid === MIST_DELIVERY_METHOD) {
        return
      }

      const params = {
        offset: queryParams.offset,
        limit: 25,
        search: queryParams?.search || ''
      }

      this.departments.fetching = true

      $api<ServerListResponse<Department>>(
        `api/deliveries/post-offices/${getDeliveryService(deliveryMethodUuid)}/${cityUuid}/`,
        {
          params,
          signal
        }
      )
        .then(({ results, count }) => {
          commonMetricsStore.addMetricEvent({
            element: 'ms[checkout-search-department]',
            event_type: 'view_results',
            event_content: {
              departmentName: queryParams?.description || '',
              results
            }
          })
  
          this.departments.list = isUpdate ? [...this.departments.list, ...results] : results
  
          if (!isUpdate) {
            this.departments.count = count
          }
        })
        .catch((error) => {
          if (isErrorAbort(error)) {
            return
          }

          this.departments.list = []
          this.departments.count = 0
        })
        .finally(() => {
          this.departments.fetching = false
        })
    },

    async getCities({ queryParams, isUpdate }: { queryParams: QueryParams, isUpdate: boolean }) {
      const commonMetricsStore = useCommonMetricsStore()
      const { $api } = useNuxtApp()
      const { signal } = getAbortController()

      this.cities.fetching = true
      
      $api<ServerListResponse<SearchCity>>('/api/common/cities/',
        {
          params: queryParams,
          signal
        }
      )
        .then(({ results, count }) => {
          this.cities.list = isUpdate ? [...this.cities.list, ...results] : results

          if (!isUpdate) {
            this.cities.count = count
          }

          commonMetricsStore.addMetricEvent({
            element: 'ms[checkout-search-city]',
            event_type: 'view_results',
            event_content: {
              cityName: queryParams.name,
              results
            }
          })
        })
        .catch((error) => {
          if (isErrorAbort(error)) {
            return
          }

          this.cities.list = []
          this.cities.count = 0
        })
        .finally(() => {
          this.cities.fetching = false
        })
    }
  }
})
