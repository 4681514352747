import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

import 'dayjs/locale/uk'
import 'dayjs/locale/ru'

dayjs.extend(utc)
dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(duration)

dayjs.locale('uk')

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      dayjs
    }
  }
})