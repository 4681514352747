import { NOVAPOSHTA_DELIVERY_METHOD, SYNTHETIC_DELIVERY_METHOD, UKRPOSHTA_DELIVERY_METHOD } from '~/constants/common'

export const getDeliveryService = (deliveryMethodUuid: string) => {
  if (deliveryMethodUuid === SYNTHETIC_DELIVERY_METHOD) {
    return 'synthetic'
  } else if (deliveryMethodUuid === NOVAPOSHTA_DELIVERY_METHOD) {
    return 'nova_poshta'
  } else if (deliveryMethodUuid === UKRPOSHTA_DELIVERY_METHOD) {
    return 'ukr_poshta'
  } else {
    return ''
  }
}
