import dayjs from 'dayjs'
import type { Composer, ExportedGlobalComposer } from 'vue-i18n'
import type { FetchContext } from 'ofetch'
import type { ToastInterface } from 'vue-toastification'
import { modalOffersErrorId } from '~/constants/modalNames'
import type { ApiRefreshTokenResponse } from '~/types/api/api-auth.types'
import type { Vfm } from '~/types/modal'

export default defineNuxtPlugin((nuxtApp) => {
  const { apiBase, domain, apiBaseMessenger, apiReferral, apiMetrics, apiTM } = useRuntimeConfig().public
  const $i18n = nuxtApp.$i18n as ExportedGlobalComposer & Composer

  const localePath = useLocalePath()

  const authAccessCookie = useCookie('auth_access', {
    expires: dayjs().add(1, 'h').toDate(),
    path: '/',
    domain: domain as string,
    sameSite: 'lax'
  })
  const authRefreshCookie = useCookie('auth_refresh', {
    expires: dayjs().add(2, 'M').toDate(),
    path: '/',
    domain: domain as string,
    sameSite: 'lax'
  })
  const metricsToken = useCookie('metrics_token', {
    path: '/',
    domain: domain as string,
    sameSite: 'lax'
  })

  const authStore = useAuthStore()

  const router = useRouter()

  const apiOnResponseError = async ({ response, options }: FetchContext) => {
    if (!response) return

    switch (response.status) {
      case 400:
        if (response._data.error_code && response._data.code === 'E200000') { // Якщо офер не в наявності, то відкриваємо модалку з помилкою
          (nuxtApp.$vfm as Vfm).open(modalOffersErrorId)
        } else if (response._data?.detail) {
          if (typeof response._data.detail === 'string') { // Якщо помилка одна, то відкриваємо тост з помилкою
            (nuxtApp.$toast as ToastInterface).error(response._data.detail)
          } else if (Array.isArray(response._data.detail)) { // Якщо помилок декілька, то відкриваємо тости з кожною помилкою
            response._data.detail.forEach((error: string) => {
              (nuxtApp.$toast as ToastInterface).error(error)
            })
          }
        }

        break

      case 401:
        const isRefreshUrl = response.url.includes('/api/auth/token/refresh/')

        if (response.status === 401 && authRefreshCookie.value && !isRefreshUrl) {
          try {
            // Attempt to refresh the access token
            const { access, refresh } = await api<ApiRefreshTokenResponse>('/api/auth/token/refresh/', {
              method: 'POST',
              body: { refresh: authRefreshCookie.value },
            });

            // Update the access token in the cookie.
            authAccessCookie.value = access
            authRefreshCookie.value = refresh

            refreshCookie('auth_access')
            refreshCookie('auth_refresh')

            if (options?.headers) {
              // Retry the original request with the new access token.
              options.headers.set('Authorization', `Bearer ${access}`)
            }
          } catch {
            // If refresh fails, clear tokens.
            authAccessCookie.value = null
            authRefreshCookie.value = null

            refreshCookie('auth_access')
            refreshCookie('auth_refresh')

            document.cookie = 'auth_access=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'
            document.cookie = 'auth_refresh=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'

            authStore.logout()

            if (options?.headers) {
              options.headers.delete('Authorization')
            }
          }
        } else {
          // If refresh not found, clear tokens.
          authAccessCookie.value = null
          authRefreshCookie.value = null

          refreshCookie('auth_access')
          refreshCookie('auth_refresh')

          document.cookie = 'auth_access=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'
          document.cookie = 'auth_refresh=; path=/; domain=' + domain + '; expires=Thu, 01 Jan 1970 00:00:00 GMT'

          authStore.logout()

          if (options?.headers) {
            options.headers.delete('Authorization')
          }
        }

        break

      case 429:
        navigateTo({
          path: localePath('/captcha'),
          query: {
            next: router.currentRoute.value.path
          }
        })
        break
    }
  }

  const api = $fetch.create({
    baseURL: apiBase as string,
    headers: {},
    retry: 1,
    retryStatusCodes: [401],
    credentials: 'include',
    onRequest({ options }) {
      if (authAccessCookie.value) {
        options.headers.set('Authorization', `Bearer ${authAccessCookie.value}`)
      }

      options.headers.set('Accept-Language', $i18n.locale.value === 'ua' ? 'uk' : $i18n.locale.value)
    },
    onResponseError: apiOnResponseError,
    onResponse({ response }) {

    },
  })

  const messengerApi = $fetch.create({
    baseURL: apiBaseMessenger as string,
    onRequest({ options }) {
      if (authAccessCookie.value) {
        options.headers.set('Authorization', `Bearer ${authAccessCookie.value}`)
      }

      options.headers.set('Accept-Language', $i18n.locale.value === 'ua' ? 'uk' : $i18n.locale.value)
    }
  })

  const tmApi = $fetch.create({
    baseURL: apiTM as string,
    onRequest({ options }) {
      if (authAccessCookie.value) {
        options.headers.set('Authorization', `Bearer ${authAccessCookie.value}`)
      }

      options.headers.set('Accept-Language', $i18n.locale.value === 'ua' ? 'uk' : $i18n.locale.value)
    }
  })

  const metricsApi = $fetch.create({
    baseURL: apiMetrics as string,
    retry: 1,
    retryStatusCodes: [403],
    onRequest({ options }) {
      if (metricsToken.value) {
        options.headers.set('x-mid', metricsToken.value)
      }
    },
    onResponseError: async ({ options }) => {
      console.log('onResponseError')

      const { token } = await metricsApi<{ token: string }>('/api/v1/identity/', {
        method: 'POST'
      })

      metricsToken.value = token
      refreshCookie('metrics_token')

      options.headers.set('x-mid', token)
    }
  })

  const referralApi = $fetch.create({
    baseURL: apiReferral as string,
    headers: {},
    onRequest({ options }) {
      if (authAccessCookie.value) {
        options.headers.set('Authorization', `Bearer ${authAccessCookie.value}`)
      }

      options.headers.set('Accept-Language', $i18n.locale.value === 'ua' ? 'uk' : $i18n.locale.value)
    },
  })

  return {
    provide: {
      api,
      messengerApi,
      referralApi,
      metricsApi,
      apiOnResponseError,
      tmApi
    }
  }
})
