<template>
  <div class="landing__footer">
    <div class="landing__footer-logo">
      <NuxtLinkLocale to="/" exact>
        <img
          :alt="$t('common.logo_alt')"
          :title="$t('common.logo_title')"
          src="@/assets/images/logo-synthetic.svg"
          width="171"
          height="40"
          loading="lazy"
        >
      </NuxtLinkLocale>
    </div>
    <div class="landing__footer-icons">
      <a href="https://www.facebook.com/synthethic.ua/" target="_blank">
        <span class="icon-facebook" />
      </a>
      <a href="https://www.instagram.com/synthetic_ua/" target="_blank">
        <span class="icon-instagram" />
      </a>
      <a href="https://www.tiktok.com/@synthetic_ua" target="_blank">
        <span class="icon-tiktok" />
      </a>
      <a href="https://www.youtube.com/channel/UCStfpCVDa03L-dJyJLhDG2w" target="_blank">
        <span class="icon-youtube" />
      </a>
    </div>
    <div class="landing__copyright">
      &copy; Synthetic.ua {{ currentYear }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const { $dayjs } = useNuxtApp()
const currentYear = $dayjs().format('YYYY')
</script>

<style lang="scss" scoped>
.landing {
  &__footer {
    background-color: #FAFAFB;
    margin-top: auto;
  }

  &__footer-logo {
    display: flex;
    justify-content: center;
    padding: 30px 0 25px;
  }

  &__footer-icons {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    @media only screen and (min-width: 740px) {
      padding-bottom: 30px;
    }

    a {
      font-size: 24px;
      color: $font-color;
      text-decoration: none;
      padding: 0 11px;

      @media only screen and (min-width: 740px) {
        font-size: 30px;
      }
    }
  }

  &__copyright {
    border-top: solid 1px #E5E5E5;
    color: #8A8A8A;
    text-align: center;
    padding: 9px 0;
  }
}
</style>
