import { defineStore } from 'pinia'
import type { ApiCommonDataResponse, ApiGetCategoriesInfoPagesResponse } from '~/types/api/api-common.types'
import type { InfoPage, MenuInfoPage, MenuInfoPageParent } from '~/types/page'

interface State {
  headerPages: MenuInfoPage[]
  footerPages: MenuInfoPage[]
  soclePages: MenuInfoPage[]
  pageTermsConditions: string
  pagePrivacyPolicy: string
  pagesCategories: MenuInfoPageParent[]
}

export const useCommonPagesStore = defineStore('commonPages', {
  state: (): State => ({
    headerPages: [],
    footerPages: [],
    soclePages: [],
    pageTermsConditions: '',
    pagePrivacyPolicy: '',
    pagesCategories: []
  }),
  actions: {
    async getInfoPages() {
      const { $api } = useNuxtApp()

      return $api<ApiCommonDataResponse>('/api/common/')
        .then(({ header, footer, socle }) => {
          this.headerPages = header.general || []
          this.footerPages = footer.general || []
          this.soclePages = socle.general || []
        })
    },

    getPageTermsConditions() {
      if (this.pageTermsConditions) {
        return
      }

      const $api = useNuxtApp().$api

      $api<InfoPage>('/api/common/info-pages/terms-of-use/')
        .then(({ body }) => {
          this.pageTermsConditions = body
        })
    },

    getPagePrivacyPolicy() {
      if (this.pagePrivacyPolicy) {
        return
      }

      const $api = useNuxtApp().$api

      $api<InfoPage>('/api/common/info-pages/privacy_policy/')
        .then(({ body }) => {
          this.pagePrivacyPolicy = body
        })
    },

    async getInfoPagesCategories() {
      const { $api } = useNuxtApp()

      return new Promise<MenuInfoPageParent[]>((resolve, reject) => {
        return $api<ApiGetCategoriesInfoPagesResponse>('/api/common/info-page-categories/')
          .then(({ results }) => {
            
            this.pagesCategories = results

            resolve(results)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})
