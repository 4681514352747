export const useWishlistOffersStore = defineStore('wishlistOffers', () => {
  const { $api } = useNuxtApp()

  const offerCodes = ref(new Set<string>())

  const getWishlistOffersCodes = async () => {
    try {
      const { results } = await $api<{ results: string[] }>('/api/users/profile/wishlists/offers-codes/')

      offerCodes.value = new Set(results)
    } catch (e) {
      throw e
    }
  }

  const addToWishlist = (offerCode: string) => {
    offerCodes.value.add(offerCode)

    return $api('/api/users/profile/wishlists/offers/', {
      method: 'POST',
      body: {
        code: offerCode
      }
    })
  }

  const removeFromWishlist = (offerCode: string) => {
    const wishlistStore = useWishlistStore()

    offerCodes.value.delete(offerCode)
    wishlistStore.removeFromWishlist(offerCode)
  }

  const resetState = () => {
    offerCodes.value.clear()
  }

  return {
    offerCodes,
    getWishlistOffersCodes,
    addToWishlist,
    removeFromWishlist,
    resetState
  }
})
