import { isValidPhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js'
import type { CountryCode } from 'libphonenumber-js'

export const getPhoneNumber = (phone: string) => {
  const number = phone.replace(/\D+/g, '')

  return `380${number.slice(-9)}`
}

export const parsedMobilePhone = (phoneNumber: number | string) => {
  const number = phoneNumber.toString()

  return (
    '+' +
    number.slice(0, 3) +
    ' (' +
    number.slice(3, 5) +
    ') ' +
    number.slice(5, 8) +
    '-' +
    number.slice(8, 10) +
    '-' +
    number.slice(10, 12)
  )
}

export const syntheticPhoneValidation = (phoneNumber: string, phoneCountry: CountryCode) => {
  if (phoneCountry === 'UA') {
    const mobilePhoneObject = parsePhoneNumberWithError(phoneNumber, phoneCountry)

    return isValidPhoneNumber(phoneNumber, phoneCountry) && mobilePhoneObject.nationalNumber.length === 9
  } else {
    return isValidPhoneNumber(phoneNumber, phoneCountry)
  }
}
