<template>
  <div class="landing-404-wrapper">
    <div
      class="landing-404"
    >
      <div class="landing-404__img">
        <img src="@/assets/images/404-landing-img.png">
      </div>
      <div class="landing-404__title">
        {{ $t('common.landing_not_found_title') }}
      </div>
      <div class="landing-404__text">
        {{ $t('common.landing_not_found_text') }}
      </div>
      <div class="landing-404__button">
        <BaseButton
          :to="localePath('/')"
          full-width
        >
          {{ $t('common.back_to_home') }}
        </BaseButton>
      </div>

      <RefferalLandingFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.landing-404-wrapper {
  display: flex;
  justify-content: center;
}

.landing-404 {
  min-height: 100vh;
  width: 100%;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  @media screen and (min-width: 768px) {
    padding: 0 24px;
    max-width: 455px;
    padding-top: 150px;
  }

  &__img {
    height: 205px;
    background: transparent url('@/assets/images/404-landing-bg.png') 50% 50% no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;

    @media screen and (min-width: 768px) {
      font-size: 42px;
      margin-bottom: 40px;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 60px;
    }
  }
}
</style>
