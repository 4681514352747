import type { PluginOptions } from 'vue-toastification';
import * as vt from 'vue-toastification'
import Toast from 'vue-toastification'

export default defineNuxtPlugin((nuxtApp) => {
  const options: PluginOptions = {
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    rtl: false,
    transition: 'Vue-Toastification__fade',
    timeout: 3000,
    maxToasts: 6,
    closeOnClick: true,
    closeButton: false,
    draggable: true,
    draggablePercent: 0.2,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    icon: 'icon-notification',
    bodyClassName: 'toast__body',
    toastClassName: 'toast',
    toastDefaults: {
      success: {
        toastClassName: 'toast--success'
      },
      error: {
        toastClassName: 'toast--error'
      }
    }
  };

  nuxtApp.vueApp.use(Toast, options);

  return {
    provide: {
      toast: vt.useToast()
    }
  }
})