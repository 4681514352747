<template>
<!--  <NuxtPwaManifest />-->
  <NuxtLayout>
    <NuxtPage :page-key="getPageKey" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import logoSynthetic from '@/assets/images/logo-synthetic.svg'
import { KEY_COOKIES_USER_FIRST_ENTRY } from './constants/common'
import type { RouteLocationNormalizedLoadedGeneric } from '#vue-router'

const commonStore = useCommonStore()
const { siteUrl, cdnUrl, apiBase, gtmEnabled, gtmKey } = useRuntimeConfig().public
const { $dayjs } = useNuxtApp()
const route = useRoute()
const { t, locale } = useI18n()
const localePath = useLocalePath()
const path = unref(route).fullPath
const fullUrl = `${siteUrl}${unref(route).fullPath}`
const currentLanguage = computed(() => {
  return locale.value === 'ua' ? 'uk' : locale.value
})

const isIconPrefix = computed<string>(() => {
  return commonStore.isIconPrefix
})

const pageTitle = computed(() => {
  return t('common.title')
})

const { headTitle } = useFaviconNotification(pageTitle)

const rsidCookie = useCookie<string>('rsid', {
  expires: $dayjs().add(7, 'd').toDate(),
  path: '/',
  sameSite: 'strict'
})
const userFirstEntryCookie = useCookie(KEY_COOKIES_USER_FIRST_ENTRY, {
  expires: $dayjs().add(1, 'y').toDate(),
  path: '/',
  sameSite: 'strict'
})

const setRsid = () => {
  const rsidInQuery = unref(route).query.rsid as string

  if (rsidInQuery) {
    rsidCookie.value = rsidInQuery
  }
}

const checkUserFirstEntry = () => {
  if (!userFirstEntryCookie.value || userFirstEntryCookie.value !== 'true') {
    userFirstEntryCookie.value = 'true'
  }
}

const getPageKey = (route: RouteLocationNormalizedLoadedGeneric) => {
  if (route.name?.toString().startsWith('category')) {
    return route.params.categoryId as string
  }

  return route.path
}

if (navigator && 'serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    if (registrations.length === 0) {
      return
    }

    Promise.all(registrations.map((registration) => registration.unregister()))
      .then(() => {
        if (!window) {
          return
        }
        // Очищення кешу PWA
        if ('caches' in window) {
          caches.keys().then((names) => {
            Promise.all(names.map((name) => caches.delete(name))).then(() => {
              window.location.reload()
            })
          })
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('Помилка при видаленні сервіс-воркерів:', error)
      })
  })
}

onBeforeMount(() => {
  checkUserFirstEntry()
  setRsid()
})

useHead({
  htmlAttrs: {
    lang: currentLanguage.value,
    dir: 'ltr'
  },
  meta: [
    { name: 'robots', content: 'index, follow' },
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'referrer', content: 'origin' },
    { name: 'msapplication-TileColor', content: '#161616' },
    { name: 'msapplication-TileImage', content: '/ms-tile-144.png' },
    { name: 'msapplication-config', content: '/browserconfig.xml' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'google-site-verification', content: 'vv1o96DVeCFgma04hMvJuMQFMFmxjARqzDvbDuM4GwQ' }
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: `/${isIconPrefix.value}favicon.ico` },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/${isIconPrefix.value}favicon-16x16.png` },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/${isIconPrefix.value}favicon-32x32.png` },
    { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/android-chrome-192x192.png' },
    { rel: 'icon', type: 'image/png', sizes: '256x256', href: '/android-chrome-256x256.png' },
    { rel: 'icon', type: 'image/png', sizes: '384x384', href: '/android-chrome-384x384.png' },
    { rel: 'icon', type: 'image/png', sizes: '512x512', href: '/android-chrome-512x512.png' },
    { rel: 'mask-icon', href: '/icon.svg', color: '#ffffff' },
    { rel: 'icon', type: 'image/svg+xml', sizes: 'any', href: `/${isIconPrefix.value}icon.svg` },
    { rel: 'apple-touch-icon', sizes: '120x120', href: '/apple-touch-icon-120.png' },
    { rel: 'apple-touch-icon', sizes: '152x152', href: '/apple-touch-icon-152.png' },
    { rel: 'apple-touch-icon', sizes: '167x167', href: '/apple-touch-icon-167.png' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },

    { rel: 'alternate', hreflang: 'uk', href: `${siteUrl}${localePath(path, 'ua')}` },
    { rel: 'alternate', hreflang: 'uk-UA', href: `${siteUrl}${localePath(path, 'ua')}` },
    { rel: 'alternate', hreflang: 'ru', href: `${siteUrl}${localePath(path, 'ru')}` },
    { rel: 'alternate', hreflang: 'ru-UA', href: `${siteUrl}${localePath(path, 'ru')}` },

    { rel: 'preconnect', href: cdnUrl as string },
    { rel: 'preconnect', href: apiBase as string },
    { rel: 'preconnect', href: 'https://www.googletagmanager.com' },
    { rel: 'dns-prefetch', href: 'https://www.google.com' },
    { rel: 'dns-prefetch', href: 'https://www.google.com.ua' },
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300..800&display=swap' },
  ],
  script: [
    gtmEnabled
      ? {
        tagPriority: 'low',
        innerHTML: `window.dataLayer = window.dataLayer || [];`
      }
      : {},
    gtmEnabled
      ? {
        tagPriority: 'low',
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmKey}');`
      }
      : {}
  ],
  noscript: [
    gtmEnabled
      ? {
        tagPriority: 'low',
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmKey}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        tagPosition: 'bodyClose'
      }
      : {}
  ]
})


useSeoMeta({
  title: () => headTitle.value,
  description: () => t('common.description'),
  keywords: t('common.keywords'),
  ogTitle: () => headTitle.value,
  ogDescription: () => t('common.description'),
  ogUrl: () => fullUrl,
  ogImage: logoSynthetic,
  twitterTitle: () => headTitle.value,
  twitterDescription: () => t('common.description'),
  twitterSite: () => fullUrl,
  twitterImage: logoSynthetic,
  twitterCard: 'summary_large_image'
})
</script>