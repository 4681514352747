import type { CountryCode } from 'libphonenumber-js'
import { defineStore } from 'pinia'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { useAuthUserStore } from './auth-user'
import { useCartStore } from './cart'
import { useCommonMetricsStore } from './common-metrics'
import { useCheckoutShipmentStore } from './checkout-shipment'
import { useCheckoutMetaStore } from './checkout-meta'
import type { CartItem, CartOrder, OfferIsAvailable, OrderCompleted, PayloadSubmitCheckout } from '~/types/checkout'
import type { Delivery, DeliveryConfig, Department } from '~/types/delivery'
import { AMAZON_DELIVERY_UUID, DELIVERY_COURIER_SELLER, DELIVERY_DEPARTMENT, DELIVERY_SYNTHETIC_DEPARTMENT, MIST_DELIVERY_METHOD, NOVAPOSHTA_DELIVERY_METHOD, ORDER_COMPLETED_KEY, SYNTHETIC_DELIVERY_METHOD, UKRPOSHTA_DELIVERY_METHOD } from '~/constants/common'
import { modalShipmentDeliveryChangedNotificationId } from '~/constants/modalNames'
import type { ServerListResponse } from '~/types/api/response.types'
import type { ApiGetCheckoutResponse, ApiSubmitCheckoutByUUIDResponse, ApiSubmitCheckoutData } from '~/types/api/api-carts.types'
import type { ApiRecaptchaResponse } from '~/types/api/api-common.types'

interface State {
  uuid: string | null
  delivery: Delivery | null
  supportedDeliveryConfigs: DeliveryConfig[]
  submitProcessing: boolean
  orderCompleted: OrderCompleted | null
  showCaptcha: boolean
  mobilePhoneCountryCode: CountryCode
  shipmentDataFetching: boolean
  promoCode: {
    value: string
    valid: boolean | null
    fetching: boolean
  }
}

export const useCheckoutStore = defineStore('checkout', {
  state: (): State => ({
    uuid: null,
    delivery: null,
    supportedDeliveryConfigs: [],
    submitProcessing: false,
    orderCompleted: null,
    showCaptcha: false,
    mobilePhoneCountryCode: 'UA',
    shipmentDataFetching: false,
    promoCode: {
      value: '',
      valid: null,
      fetching: false
    }
  }),
  getters: {
    order(state): CartOrder | undefined {
      const cartStore = useCartStore()

      return cartStore.orders.find((order: CartOrder) => order.uuid === state.uuid)
    },

    offers(): CartItem[] {
      return this.order !== undefined ? this.order.items : []
    },

    promoCodeDiscount(): number {
      return this.order?.items.reduce((total, offer) => {
        if (offer.additionalInfo.isTempRemoved) {
          return total
        }

        return total + (offer?.additionalInfo?.promoCodeAmount || 0)
      }, 0) || 0
    },

    totalPrice(): number {
      return this.order?.items.reduce((total: number, offer: CartItem) => {
        if (offer.additionalInfo.isTempRemoved) {
          return total
        }

        return total + offer.price_total
      }, 0) || 0
    },

    totalPriceWithDiscount(): number {
      const authUserStore = useAuthUserStore()

      return this.totalPrice - this.promoCodeDiscount - authUserStore.selectedDiscount
    },

    promoCodeInvalid(state) {
      return state.promoCode.value.length > 0 && state.promoCode.valid === false
    },
    promoCodeApplied(state) {
      return state.promoCode.value.length > 0 && state.promoCode.valid === true
    }
  },
  actions: {
    addPromoCode(promoCode: string) {
      const authUserStore = useAuthUserStore()
      const cartStore = useCartStore()
      const { $api } = useNuxtApp()

      this.updatePromoCodeFetching(true)

      $api<ServerListResponse<OfferIsAvailable>>(
        `/api/carts/current/orders/${this.order?.uuid}/is-available/?promo_code=${promoCode}`
      )
        .then(({ results }) => {
          const hasPromoCodeDiscount = results.some((item) => item.promo_code_amount !== null)

          // Check if promo code valid
          if (hasPromoCodeDiscount) {
            this.setPromoCodeValid(true)
            authUserStore.setSelectedDiscount(0)
          } else {
            this.setPromoCodeValid(false)
          }

          cartStore.addPromoCodeDiscountToOrderItems({ orderId: this.order?.uuid || '', results })
        })
        .catch(() => {
          this.setPromoCodeValid(false)
        })
        .finally(() => {
          this.updatePromoCodeFetching(false)
        })
    },

    updatePromoCodeFetching(fetchingState: boolean) {
      this.promoCode.fetching = fetchingState
    },

    setPromoCodeValid(validationValue: boolean | null) {
      this.promoCode.valid = validationValue
    },

    setPromoCodeValue(promoCodeValue: string) {
      this.promoCode.value = promoCodeValue
    },

    resetPromoCode() {
      const cartStore = useCartStore()

      this.promoCode.value = ''
      this.promoCode.valid = null

      if (this.uuid) {
        cartStore.resetPromoCodeDiscount(this.uuid)
      }
    },

    async updateSupportedDeliveryConfigs() {
      const checkoutShipmentStore = useCheckoutShipmentStore()
      const shipmentCity = checkoutShipmentStore.city
      const { $api, $vfm } = useNuxtApp()

      // Get departments for current shipment city
      const [syntheticDepartments, novaPoshtaDepartments, ukrPoshtaDepartments] = await Promise.all([
        $api<ServerListResponse<Department>>(`/api/deliveries/post-offices/synthetic/${shipmentCity?.uuid}/`),
        $api<ServerListResponse<Department>>(`/api/deliveries/post-offices/nova_poshta/${shipmentCity?.uuid}/`),
        $api<ServerListResponse<Department>>(`/api/deliveries/post-offices/ukr_poshta/${shipmentCity?.uuid}/`)
      ])

      // Set available status for all delivery services
      const availableDepartmentsInCity: { [key: string]: boolean } = {
        [SYNTHETIC_DELIVERY_METHOD]: syntheticDepartments.count > 0,
        [NOVAPOSHTA_DELIVERY_METHOD]: novaPoshtaDepartments.count > 0,
        [UKRPOSHTA_DELIVERY_METHOD]: ukrPoshtaDepartments.count > 0,
        [MIST_DELIVERY_METHOD]: true
      }

      const supportedConfigs = this.delivery?.configs.filter((config) => {
        switch (config.delivery_method.type) {
          case DELIVERY_COURIER_SELLER: {
            return shipmentCity?.uuid === this.delivery?.shipment_city.uuid
          }

          case DELIVERY_DEPARTMENT:
          case DELIVERY_SYNTHETIC_DEPARTMENT: {
            // Return delivery service if available departments in current shipment city
            return availableDepartmentsInCity[config.delivery_method.uuid]
          }

          default: {
            return true
          }
        }
      }) || []

      const shipmentDeliveryMethod = checkoutShipmentStore.delivery.method
      const hasSuportedMethod = supportedConfigs.findIndex((config) => {
        return config.delivery_method.uuid === shipmentDeliveryMethod
      }) !== -1

      if (!hasSuportedMethod && supportedConfigs.length > 0 && shipmentDeliveryMethod.length > 0) {
        $vfm.open(modalShipmentDeliveryChangedNotificationId)

        // Change delivery method if current method not fount in supportedConfigs
        checkoutShipmentStore.changeShipmentMethod({
          deliveryMethod: supportedConfigs[0].delivery_method.uuid,
          deliveryConfig: supportedConfigs[0].uuid
        })
      }

      this.supportedDeliveryConfigs = supportedConfigs
    },

    async getCheckout(uuid: string) {
      const checkoutMetaStore = useCheckoutMetaStore()
      const checkoutShipmentStore = useCheckoutShipmentStore()
      const { $api } = useNuxtApp()

      this.uuid = uuid
      
      return new Promise((resolve, reject) => {
        $api<ApiGetCheckoutResponse>(`/api/carts/current/checkout/${encodeURIComponent(uuid)}/`)
          .then(async (responseOrder) => {
            await Promise.all([
              // Fetch offer dlivery methods
              this.getOrderDelivery(responseOrder.delivery.uuid),
              // Fetch default cities for city dropdown
              checkoutMetaStore.getCities({
                queryParams: {
                  offset: 0,
                  name: ''
                },
                isUpdate: false
              }),
              // Fetch default city for city dropdown
              checkoutShipmentStore.setDefaultShipmentCity(),
              // Set default receiver data after order delivery fetching
              checkoutShipmentStore.setDefaultReceiverData()
            ])
    
            // Set only supported configs for current shipment city
            await this.updateSupportedDeliveryConfigs()
    
            // Set default delivery method after order delivery fetching
            await checkoutShipmentStore.setDefaultDeliveryMethod()
            // Set default payment method after order delivery fetching
            checkoutShipmentStore.setDefaultPaymentMethod()

            resolve(responseOrder)
          })
          .catch(reject)
      })
    },

    async submitCheckoutWithRecaptcha(options: PayloadSubmitCheckout) {
      const { token } = options
      const { $api, $toast, $i18n } = useNuxtApp()

      $api<ApiRecaptchaResponse>('/api/common/recaptcha/', {
        params: {
          token
        }
      })
        .then(({ success }) => {
          if (success) {
            this.showCaptcha = false

            return this.submitCheckout(options)
          }

          return Promise.reject({ success: false })
        })
        .catch(() => {
          $toast.error($i18n.t('common.invalie_recaptcha'))

          return Promise.reject({ success: false })
        })
    },

    async submitCheckout({ uuid, itemsInOrder = [] }: PayloadSubmitCheckout) {
      const { $dayjs, $toast, $i18n, $api, $gtm } = useNuxtApp()
      const localePath = useLocalePath()

      if (this.order !== null) {
        const checkoutShipmentStore = useCheckoutShipmentStore()
        const commonMetricsStore = useCommonMetricsStore()
        const authUserStore = useAuthUserStore()
        const cartStore = useCartStore()
        let deliveryAddress = ''

        this.submitProcessing = true

        const selectedDeliveryConfig = checkoutShipmentStore.selectedDeliveryConfig

        // якщо apartment не був введений користувачем
        const defaultApartment =
        checkoutShipmentStore.delivery.address && checkoutShipmentStore.delivery.house
          ? '1'
          : ''

        if ([DELIVERY_DEPARTMENT, DELIVERY_SYNTHETIC_DEPARTMENT].includes(selectedDeliveryConfig?.delivery_method.type || '')) {
          // deliveryAddress = state.shipment.delivery.method === SYNTHETIC_DELIVERY_METHOD
          //   ? state.shipment.delivery.department.number
          //   : state.shipment.delivery.departmentString
          deliveryAddress = checkoutShipmentStore.delivery.method === MIST_DELIVERY_METHOD
            ? checkoutShipmentStore.delivery.departmentMIST
            : checkoutShipmentStore.delivery.department?.public_title || ''
        } else {
          deliveryAddress = checkoutShipmentStore.delivery.address
        }

        try {
          const mobilePhone = parsePhoneNumberWithError(checkoutShipmentStore.receiver.mobilePhone, this.mobilePhoneCountryCode)
          const {
            comment,
            city,
            delivery,
            payment,
            receiver,
            dontCallMe
          } = checkoutShipmentStore

          let payload: ApiSubmitCheckoutData = {
            uuid: this.order ? this.order.uuid : '',

            delivery_city: city?.public_name || '',

            delivery_config: delivery.config,
            delivery_method: delivery.method,
            delivery_address: deliveryAddress,
            delivery_house: delivery.house,
            delivery_apartment: delivery.apartment || defaultApartment,

            payment_method: payment.method,

            receiver_first_name: receiver.firstName,
            receiver_last_name: receiver.lastName,
            receiver_mobile_phone: mobilePhone.format('E.164'),
            receiver_email: receiver.email,

            comment_customer: `
               ${(this.promoCodeInvalid || this.promoCodeApplied) ? `Промокод: ${this.promoCode.value}.` : ''}
              ${comment}
            `,
            dont_call_me: dontCallMe,
            max_number_of_payments: payment.maxNumberOfPayments,
            items: itemsInOrder
          }

          // Add promo code if applied
          if (this.promoCodeApplied) {
            payload = {
              ...payload,
              promo_code: this.promoCode.value
            }
          }

          const rsid = useCookie('rsid').value

          const {
            code,
            success,
            payment_redirect_url: paymentRedirectUrl,
            api_integration: apiIntegration,
            err_msg: errorMsg
          } = await $api<ApiSubmitCheckoutByUUIDResponse>(`/api/carts/current/ship/${uuid}/`, {
            method: 'POST',
            body: payload,
            params: {
              ...(rsid ? { rid: rsid } : {})
            }
          })

          if (success) {
            const deliveryDays = selectedDeliveryConfig?.delivery_method.uuid === AMAZON_DELIVERY_UUID
              ? 21
              : (selectedDeliveryConfig?.shipment_time_in_days || 0) + 5
            const deliveryDate = $dayjs().add(deliveryDays, 'day').format('YYYY-MM-DD')

            const payloadCompleted: OrderCompleted = {
              uuid,
              code,
              dontCallMe: checkoutShipmentStore.dontCallMe,
              mobilePhone: mobilePhone.formatInternational(),
              email: checkoutShipmentStore.receiver.email,
              deliveryDate,
              receiverFirstName: checkoutShipmentStore.receiver.firstName,
              apiIntegration,
              errorMsg
            }

            $gtm.purchase({
              orderId: uuid,
              items: this.order?.items.map((item) => ({
                id: item.code,
                name: item.product.name,
                // category: item.category,
                price: item.price_total,
                quantity: item.quantity
              })) || []
            })

            commonMetricsStore.addMetricEvent({
              element: 'ms[checkout-submit]',
              event_type: 'click',
              event_content: {
                order: uuid
              }
            })

            commonMetricsStore.sendMetrics()

            if (paymentRedirectUrl) {
              const orderCompletedCookie = useCookie(ORDER_COMPLETED_KEY, {
                expires: $dayjs().add(1, 'd').toDate(),
                path: '/',
                sameSite: 'strict'
              })

              // Set orderCompleted to localStorage for show complete modal after return from payment page
              orderCompletedCookie.value = JSON.stringify(payloadCompleted)

              window.location.replace(paymentRedirectUrl)
            } else {
              this.setOrderCompleted(payloadCompleted)

              cartStore.clearCartOrders(uuid)

              this.clearCheckout()

              // оновлюємо бонуси користувача
              if (authUserStore.isAuthenticated) {
                await authUserStore.getBill()
              }

              navigateTo(localePath('/checkout/success'))
            }
          }

          return success
        } catch (e: any) {
          if (e.response === undefined) {
            $toast.error($i18n.t('checkout.order_not_created'))
          } else if (e.response.status === 409) {
            this.showCaptcha = true
          } else if (e.response.data.items && e.response.data.items.length > 0) {
            // toastCallWrapper(e.response.data.items, this.$nuxt.$toast.error)
          } else if (!e.response.data.detail) {
            $toast.error($i18n.t('checkout.order_not_created'))
          } else if (e.response.status === 404) {
            $toast.error($i18n.t('checkout.order_already_completed'))
            const route = authUserStore.isAuthenticated
              ? {
                  path: localePath('/profile/orders/'),
                  query: this.order ? { order: this.order.uuid } : {}
                }
              : { path: localePath('/') }

            navigateTo(route)
          }

          throw e
        } finally {
          this.submitProcessing = false
        }
      }
    },

    clearCheckout() {
      this.resetPromoCode()

      this.uuid = null
      this.delivery = null
      this.supportedDeliveryConfigs = []
    },

    setOrderCompleted(payload: OrderCompleted) {
      this.orderCompleted = payload
    },

    clearOrderCompleted() {
      this.orderCompleted = null

      useCookie(ORDER_COMPLETED_KEY).value = null
    },

    async getOrderDelivery(deliveryUuid: string) {
      const { $api } = useNuxtApp()

      return new Promise((resolve, reject) => {
        $api<Delivery>(`/api/deliveries/${deliveryUuid}/`)
          .then((delivery) => {
            this.delivery = delivery

            resolve(delivery)
          })
          .catch((e) => {
            this.delivery = null

            reject(e)
          })
      })
    },

    changeMobilePhoneCountryCode(payload: CountryCode) {
      this.mobilePhoneCountryCode = payload
    }
  }
})
