import type { Pinia } from "pinia"

const storeIds = ['wishlist-offers', 'authUser', 'cart', 'checkout']

export default defineNuxtPlugin((nuxtApp) => {
  (nuxtApp.$pinia as Pinia).use(({ store }) => {
    const bc = new BroadcastChannel(`pinia-${store.$id}`)

    bc.addEventListener('message', (e) => {
      store.$patch({
        ...JSON.parse(e.data.state)
      })
    })

    if (storeIds.includes(store.$id)) {
      store.$subscribe((event, state) => {
        if (event.type === 'direct') {
          bc.postMessage({
            storeId: store.$id,
            state: JSON.stringify(state)
          })
        }
      })
    }
  })
})