export default defineNuxtRouteMiddleware((to) => {
  // Check if the path starts with /ru/ru
  if (to.path.startsWith('/ru/ru')) {
    // Replace /ru/ru with /ru and maintain the rest of the path
    const correctedPath = to.path.replace('/ru/ru', '/ru')
    
    // Preserve query parameters and hash in the redirect
    return navigateTo(correctedPath + (to.query ? `?${new URLSearchParams(to.query as Record<string, string>)}` : '') + (to.hash || ''), {
      redirectCode: 301 // Permanent redirect
    })
  }
}) 