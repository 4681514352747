export const modalAuthId = 'modal-auth'
export const modalLoginId = 'modal-login'
export const modalGalleryId = 'gallery'

export const modalCartId = 'cart'

export const modalCreateReviewSellerId = 'create_seller_review'
export const modalPriceHistoryId = 'modal-price-history'
export const modalReviewMediaProductId = 'review-media-product'
export const modalReferralId = 'modal-referral-invite'
export const modalSellerReviewErrorId = 'seller-reviews-error'
export const modalProductBuyOwnPriceId = 'product-buy-modal-own-price'

export const modalReportProductId = 'report-product'

export const modalResetPaymentId = 'reset-payment'
export const modalCancelOrderId = 'cancel_order'
export const modalUnsubscribeId = 'unsubscribe'
export const modalCreateReviewSuccessId = 'create-review-success'
export const modalOfferFastChoiceId = 'offer-fast-choice'
export const modalOffersErrorId = 'offers-error'
export const modalProfilePhoneConfirmId = 'profile_confirm_mobile_phone'
export const modalOrderReviewId = 'order-review-seller-or-product'
export const modalOrderFailedCancelId = 'order-failed-cancel'
export const modalCancelSubscribeId = 'cancelSubscribe'
export const modalSellerSubscribeId = 'seller-subscribers-list'
export const modalSizeTableId = 'sizeTable'
export const modalCustomPageModalId = 'custom-page-modal'
export const modalShipmentDeliveryChangedNotificationId = 'shipment-delivery-changed-notification'
export const modalShipmentPaymentChangedNotificationId = 'shipment-payment-changed-notification'
export const modalSelectSearchId = (name: string) => `select-search-${name}`

export const modalProfileInfoId = 'update-profile-info'

export const modalMessengerUnsubscribeId = 'messenger_unsubscribe'
export const modalSyntheticDepartmentsId = 'synthetic-departments'

export const modalSellerRegistrationId = 'seller-registration'
export const modalWaitForTheCallId = 'wait-for-the-call'
export const modalSorrySellId = 'sorry-sell'
export const modalShortRegistrationId = 'modal-short-registration'
export const modalSellerOfficeId = 'modal-seller-office'

// в майбутньому винести модаку окремо від батьківського компоненту
export const modalUpdatePhotoId = 'update-photo-modal'
export const modalUpdatePhotoErrorId = 'update-photo-error'

export const modalCreateReplyId = 'create_reply'
export const modalOfferDiscountId = 'modal-offer-discount'

export const modalUpdatePasswordId = 'update_password'
export const modalUpdateMobileId = 'update-mobile'
export const modalUpdateEmailId = 'update-email'
export const modalReviewUpdateEmailId = 'review-update-email'
export const modalConfirmationEmailId = 'confirmation_email'

export const modalAuctionRules = 'auction_rules'

export const modalGetPromoCodeId = 'get-promo-code'
