
declare global {
  interface Window {
    dataLayer: any[]
  }
}

type GtmPurchasePayload = {
  orderId: string
  items: { 
    id: string
    name: string
    // categoryName: string
    price: number
    quantity: number
   }[]
}

type GtmAddToCartPayload = { 
  id: string
  name: string
  categoryName: string
  price: number
  quantity: number
}

type GtmViewProductPayload = {
  id: string
  name: string
  categoryName: string
  price: number
}

type GtmViewCatalogPayload = {
  items: {
    id: string
    name: string
    categoryName: string
    price: number
  }[]
}

type GtmViewSearchPayload = {
  items: {
    id: string
    name: string
    categoryName: string
    price: number
  }[]
}
export default defineNuxtPlugin((nuxtApp) => {
  window.dataLayer = window.dataLayer || [];

  const { gtmEnabled } = useRuntimeConfig().public

  const viewProductPage = (payload: GtmViewProductPayload) => {
    if (gtmEnabled) {
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          value: payload.price,
          currency: 'UAH',
          items: [{
            item_id: payload.id,
            item_name: payload.name,
            item_category: payload.categoryName,
            price: payload.price,
            google_business_vertical: 'retail'
          }]
        }
      })
    }
  }

  const viewCatalogPage = (payload: GtmViewCatalogPayload) => {
    if (gtmEnabled) {
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          value: payload.items.reduce((acc, item) => acc + item.price, 0),
          currency: 'UAH',
          items: payload.items.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            item_category: item.categoryName,
            price: item.price,
            google_business_vertical: 'retail'
          }))
        }
      })
    }
  }

  const viewSearchPage = (payload: GtmViewSearchPayload) => {
    if (gtmEnabled) {
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({
        event: 'view_search_results',
        ecommerce: {
          value: payload.items.reduce((acc, item) => acc + item.price, 0),
          currency: 'UAH',
          items: payload.items.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            item_category: item.categoryName,
            price: item.price,
            google_business_vertical: 'retail'
          }))
        }
      })
    }
  }

  const viewTagPage = (payload: GtmViewCatalogPayload) => {
    if (gtmEnabled) {
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          value: payload.items.reduce((acc, item) => acc + item.price, 0),
          currency: 'UAH',
          items: payload.items.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            item_category: item.categoryName,
            price: item.price,
            google_business_vertical: 'retail'
          }))
        }
      })
    }
  }

  const addToCart = (payload: GtmAddToCartPayload) => {
    if (gtmEnabled) {
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          value: payload.price,
          currency: 'UAH',
          items: [{
            item_id: payload.id,
            item_name: payload.name,
            item_category: payload.categoryName,
            price: payload.price,
            quantity: payload.quantity,
            google_business_vertical: 'retail'
          }]
        }
      })
    }
  }

  const purchase = (payload: GtmPurchasePayload) => {
    if (gtmEnabled) {
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: payload.orderId,
          value: payload.items.reduce((acc, item) => acc + (item.price * item.quantity), 0),
          currency: 'UAH',
          items: payload.items.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            // item_category: item.category,
            price: item.price,
            quantity: item.quantity,
            google_business_vertical: 'retail',
          }))
        }
      })
    }
  }

  return {
    provide: {
      gtm: {
        viewProductPage,
        viewCatalogPage,
        viewSearchPage,
        viewTagPage,
        addToCart,
        purchase
      }
    }
  }
})
