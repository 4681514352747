import type { ModalExposed, ModalId, Vfm } from '@/types/modal'
import type { ComponentInternalInstance } from 'vue'

// Змінна для активного екземпляра VFM
// let activeVfm: Vfm | undefined

// Функція для встановлення активного екземпляра VFM
// export const setActiveVfm = (vfm: Vfm | undefined) => {
//   activeVfm = vfm
// }

// Базовий об'єкт VFM за замовчуванням
// const defaultVfm: Vfm = {
//   modals: [],
//   openedModals: [],
//   openedModalOverlays: [],
//   get: () => undefined,
//   toggle: () => undefined,
//   open: () => undefined,
//   close:  () => undefined,
//   closeAll: () => Promise.allSettled([]),
// }

// Функція для отримання активного VFM через ін'єкцію або поточний екземпляр
// export const getActiveVfm = (): Vfm | undefined => {
//   return activeVfm || defaultVfm
// }

// Створення нового екземпляра VFM
function createVfm() {
  // Реактивні масиви для модалок та їхнього стану
  const modals = <ComponentInternalInstance[]>[]
  const openedModals = <ComponentInternalInstance[]>[]
  const openedModalOverlays = <ComponentInternalInstance[]>[]

  // Створюємо новий об'єкт VFM
  const vfm: Vfm = markRaw({
    modals,
    openedModals,
    openedModalOverlays,
    get(modalId: ModalId) {
      return modals.find((modal) => {
        return unref(getModalExposed(modal))?.modalId === modalId
      })
    },
    toggle(modalId: ModalId, show?: boolean, params?: object) {
      const modal = vfm.get(modalId)

      return unref(getModalExposed(modal))?.toggle(show, params)
    },
    open(modalId: ModalId, params?: object) {
      return vfm.toggle(modalId, true, params)
    },
    close(modalId: ModalId) {
      return vfm.toggle(modalId, false)
    },
    closeAll() {
      // Закрити всі модалки
      return Promise.allSettled(
        openedModals.reduce<Promise<string>[]>((acc, cur) => {
          const modalExposed = getModalExposed(cur)
          const promise = unref(modalExposed)?.toggle(false)

          if (promise) {
            acc.push(promise)
          }
          return acc
        }, [])
      )
    }
  })
  // Встановлюємо активний екземпляр VFM
  // setActiveVfm(vfm)

  return vfm
}

// Допоміжна функція для отримання модалки з її стану
export function getModalExposed(componentInternalInstance: undefined | null | ComponentInternalInstance): undefined | null | ComputedRef<ModalExposed> {
  return componentInternalInstance?.exposed?.modalExposed
}

export default defineNuxtPlugin(() => {
  const vfm = createVfm()

  return {
    provide: {
      vfm
    }
  }
})
