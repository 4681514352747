import type { Dayjs } from "dayjs"

export default defineNuxtPlugin((nuxtApp) => {
  const commonCategoriesStore = useCommonCategoriesStore()
  const commonPagesStore = useCommonPagesStore()
  const commonStore = useCommonStore()

  nuxtApp.hook('i18n:beforeLocaleSwitch', ({ newLocale }) => {
    ;(nuxtApp.$dayjs as Dayjs).locale(newLocale === 'ua' ? 'uk' : 'ru')
  })

  nuxtApp.hook('i18n:localeSwitched', () => {
    commonPagesStore.getInfoPages()
    commonStore.getCityDefault()
    commonCategoriesStore.getCategories()
  })
})
