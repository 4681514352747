import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/app/.nuxt/sentry-client-config.mjs";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ from "/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_sONP248j8dJec0KgoB7asrr1_lLHUvcOb2X_iBV9nmY from "/app/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _99_api_42nzGKXF5O4MzypDNN21_4tAN_fnbgWZ6TphYjhxO9s from "/app/plugins/99.api.ts";
import common_client_dAEcbR0oKmOjKEu1nlzILciUg_9XWkLJG67xvI63XMY from "/app/plugins/common.client.ts";
import dayjs_OfOwibPyVolRS8B93_Pyn8_ay3EIa7gwAUSl8k3uQ68 from "/app/plugins/dayjs.ts";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/app/plugins/gtm.client.ts";
import i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c from "/app/plugins/i18n.ts";
import intersect_T4TMKavvYmQKCKSDMehSFFY5pMop782wdvdZ8zsHlAw from "/app/plugins/intersect.ts";
import metrics_client_BVK_jZjdDWpP9uqORy4Fc7eLYhCQuuWMYRwGobags3A from "/app/plugins/metrics.client.ts";
import modal_I_HQdWtjAMkihB994bRSOtsiTPZV2SAQ_iERvLViCAg from "/app/plugins/modal.ts";
import pinia_shared_state_client_t_DRg5j7qmGDLZjIOSvnRrbDeLg0fLdxOqu4M1crh8A from "/app/plugins/pinia-shared-state.client.ts";
import posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw from "/app/plugins/posthog.client.ts";
import recaptcha_lh6q9BA4JW_hxBMqztY0u3OzGZVC07QRobPWzAPkzqQ from "/app/plugins/recaptcha.js";
import router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60 from "/app/plugins/router.ts";
import vue_toastificaton_client_wSBcpXO_KR3auYwNqiThhn9Lq90jmdkFWqLjXZsTvf0 from "/app/plugins/vue-toastificaton.client.ts";
import vue_touch_events_YUUW_CS5GYnhwric0O6iIr_kFdHwuXz_ATkMO1ehGkw from "/app/plugins/vue-touch-events.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ,
  plugin_sONP248j8dJec0KgoB7asrr1_lLHUvcOb2X_iBV9nmY,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  _99_api_42nzGKXF5O4MzypDNN21_4tAN_fnbgWZ6TphYjhxO9s,
  common_client_dAEcbR0oKmOjKEu1nlzILciUg_9XWkLJG67xvI63XMY,
  dayjs_OfOwibPyVolRS8B93_Pyn8_ay3EIa7gwAUSl8k3uQ68,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c,
  intersect_T4TMKavvYmQKCKSDMehSFFY5pMop782wdvdZ8zsHlAw,
  metrics_client_BVK_jZjdDWpP9uqORy4Fc7eLYhCQuuWMYRwGobags3A,
  modal_I_HQdWtjAMkihB994bRSOtsiTPZV2SAQ_iERvLViCAg,
  pinia_shared_state_client_t_DRg5j7qmGDLZjIOSvnRrbDeLg0fLdxOqu4M1crh8A,
  posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw,
  recaptcha_lh6q9BA4JW_hxBMqztY0u3OzGZVC07QRobPWzAPkzqQ,
  router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60,
  vue_toastificaton_client_wSBcpXO_KR3auYwNqiThhn9Lq90jmdkFWqLjXZsTvf0,
  vue_touch_events_YUUW_CS5GYnhwric0O6iIr_kFdHwuXz_ATkMO1ehGkw,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]