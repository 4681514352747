export function useDelay(isComponent?: true) {
  const delayId = shallowRef<null | ReturnType<typeof setTimeout>>(null)

  const delayActive = computed(
    () => !!delayId.value
  )

  const clearDelay = (): void => {
    if (!delayId.value) {
      return
    }

    clearTimeout(delayId.value)
    delayId.value = null
  }

  const handleDelay = (func: () => void, timer: number): void => {
    clearDelay()

    delayId.value = setTimeout(() => {
      delayId.value = null
      func()
    }, timer)
  }

  const handleAutoDelay = (func: () => void, timer: number) => {
    handleDelay(() => {
      handleAutoDelay(func, timer)
    }, timer)
  }

  if (isComponent) {
    onBeforeUnmount(clearDelay)
  }

  return {
    handleDelay,
    clearDelay,
    handleAutoDelay,
    delayActive,
    delayId
  }
}

export function useRequestAnimation() {
  let animationFrameId: number | null = null

  const requestAnimation = (func: () => void) => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId)
    }

    animationFrameId = requestAnimationFrame(func)
  }

  onBeforeUnmount(() => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId)
    }
  })

  return {
    requestAnimation,
    animationFrameId
  }
}
